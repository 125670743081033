import { GridFooterContainer } from "@mui/x-data-grid/components/containers/GridFooterContainer";

import TableSelect from "../tableSelect/TableSelect";
import { GridPagination } from "@mui/x-data-grid/components/GridPagination";
import IconButton from "@mui/material/IconButton";
import MoveDownIcon from "@mui/icons-material/MoveDown";
import Tooltip from "@mui/material/Tooltip";
import useQuery from "../../hooks/useQuery";
import { IExplorerQuery, explorerQuerySchema } from "../explorer/ExplorerQuery";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import snack from "../../utils/snack";
import { defaultPageSize } from "./TableView";

export function TableFooter() {
  return (
    <GridFooterContainer sx={{ padding: 1 }}>
      <TableSelect />
      <Patination />
    </GridFooterContainer>
  );
}

function Patination() {
  const { query, setQuery } = useQuery<IExplorerQuery>(explorerQuerySchema);
  const numRows = useSelector(
    (state: RootState) => state.table.metadata?.numRows
  );
  if (!numRows) return null;
  return (
    <>
      <GridPagination
        // showFirstButton
        // showLastButton
        // rowsPerPage={100}
        rowsPerPageOptions={[100, 1000, 10000, 100000]}
      />
      <Tooltip title="Go to page">
        <IconButton
          onClick={() => {
            const targetRowNum = Number(
              prompt("Enter row number you want to go to")
            );
            if (!targetRowNum) return;
            if (numRows < targetRowNum) {
              snack.warning(`Row number ${targetRowNum} is out of range`);
              return;
            }
            const pageSize = query.paginationModel?.pageSize || defaultPageSize;
            const page = Math.floor(targetRowNum / pageSize);
            setQuery({
              paginationModel: {
                page,
                pageSize,
              },
            });
          }}
        >
          <MoveDownIcon />
        </IconButton>
      </Tooltip>
    </>
  );
}
