import Paper from "@mui/material/Paper";
import Scroller from "../scroller/Scroller";
import { DataGridPremium, useGridApiRef } from "@mui/x-data-grid-premium";
import { getNewspapers } from "./newspapersSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { NewspapersToolbar } from "./NewspapersToolbar";
import LinearProgress from "@mui/material/LinearProgress";
import moment from "moment";

export default function NewspapersView() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getNewspapers());
  }, []);
  const isLoading = useSelector(
    (state: RootState) => state.newspapers.status === "loading"
  );
  const rows = useSelector((state: RootState) => state.newspapers.newspapers);

  const apiRef = useGridApiRef();

  const getSelectedRows = () =>
    Array.from(apiRef.current?.getSelectedRows())
      .map((item) => item[1]?.domain)
      .filter(Boolean) || [];

  return (
    <Paper>
      <Scroller>
        <DataGridPremium
          rows={rows}
          apiRef={apiRef}
          // hideFooter
          disableRowSelectionOnClick
          checkboxSelection
          getRowId={(row) => row._id}
          slots={{
            toolbar: () => (
              <NewspapersToolbar getSelectedRows={getSelectedRows} />
            ),
            loadingOverlay: LinearProgress,
          }}
          loading={isLoading}
          columns={[
            {
              field: "domain",
              headerName: "domain",
              width: 200,
            },
            {
              field: "totalRequestCount",
              headerName: "articles_scraped",
              width: 150,
            },
            {
              field: "last_crawled_at",
              headerName: "last_crawled_at",
              width: 170,
              valueFormatter: ({ value }) =>
                value ? moment(value).format("YYYY-MM-DD hh:mma") : null,
            },
            {
              field: "last_finished_at",
              headerName: "last_finished_at",
              width: 170,
              valueFormatter: ({ value }) =>
                value ? moment(value).format("YYYY-MM-DD hh:mma") : null,
            },
            {
              field: "description",
              headerName: "description",
              width: 400,
            },
          ]}
        />
      </Scroller>
    </Paper>
  );
}
