import { Navigate, Route, Routes } from "react-router-dom";
import Login from "../features/login/Login";
import { RootState } from "../store";
import NotFound from "./NotFound";
import Wrapper from "./Wrapper";
import PipelineView from "../features/pipeline/PipelineView";
import { useSelector } from "react-redux";
import DataManagerView from "../features/dataManager/DataManager";
import FlagsSettingsView from "../features/flagsSettings/FlagsSettingsView";
import AttributesView from "../features/attributes/AttributesView";
import ExplorerView from "../features/explorer/ExplorerView";
import SmsOutreachView from "../features/smsOutreach/SmsOutreach";
import NewspapersView from "../features/newspapers/Newspapers";
import SwiperView from "../features/swiper/SwiperView";
import TableManagerView from "../features/tablesManager/TablesManager";
import ModelGardenView from "../features/modelGarden/ModelGarden";
import PhoneMaskingView from "../features/phoneMasking/PhoneMaskingView";
import UsersView from "../features/users/UsersView";

export default function Router() {
  const isLoggedIn = useSelector(
    (state: RootState) => state.session.isLoggedIn
  );
  if (!isLoggedIn) {
    return <Login />;
  }
  return (
    <Routes>
      <Route path="/" element={<Wrapper />}>
        <Route path="/" element={<Navigate to="/explorer" replace={true} />} />
        <Route path="/datamanager" element={<DataManagerView />} />
        <Route path="/pipeline" element={<PipelineView />} />
        <Route path="/flagssettings" element={<FlagsSettingsView />} />
        <Route path="/attributes" element={<AttributesView />} />
        <Route path="/explorer" element={<ExplorerView />} />
        <Route path="/outreach" element={<SmsOutreachView />} />
        <Route path="/newspapers" element={<NewspapersView />} />
        <Route path="/swiper" element={<SwiperView />} />
        <Route path="/tablemanager" element={<TableManagerView />} />
        <Route path="/models" element={<ModelGardenView />} />
        <Route path="/phoneMasking" element={<PhoneMaskingView />} />
        <Route path="/users" element={<UsersView />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}
