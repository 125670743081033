import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import useQuery from "../../hooks/useQuery";
import { IExplorerQuery } from "../explorer/ExplorerQuery";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { useDispatch } from "react-redux";
import {
  ILabelAction,
  deleteLabels,
  postLabels,
  toSalesforce,
} from "../swiper/labelsSlice";

interface IProps {
  getSelectedRows: () => string[];
}

export default function TableLabelMenu(props: IProps) {
  const { query } = useQuery<IExplorerQuery>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();

  if (!query.theme) return null;
  const handleAction = (action: ILabelAction) => () => {
    const rows = props.getSelectedRows();
    dispatch(
      postLabels({
        theme: query.theme!,
        websites: rows,
        table_id: query.tableId!,
        action,
      })
    );
    handleClose();
  };
  const sendToSf = () => () => {
    const rows = props.getSelectedRows();
    for (const website of rows) {
      dispatch(
        toSalesforce({
          theme: query.theme!,
          website: website,
          table_id: query.tableId!,
        })
      );
    }
    handleClose();
  };
  const handleDelete = () => () => {
    const rows = props.getSelectedRows();
    dispatch(
      deleteLabels({
        theme: query.theme!,
        websites: rows,
      })
    );
    handleClose();
  };

  return (
    <>
      <Tooltip title="Label selected rows as...">
        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          Label as
        </Button>
      </Tooltip>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleAction("good")}>Good</MenuItem>
        <MenuItem onClick={handleAction("bad")}>Bad</MenuItem>
        <MenuItem onClick={handleAction("trash")}>Trash</MenuItem>
        <MenuItem onClick={handleAction("re")}>Re-scrape</MenuItem>
        <MenuItem onClick={handleDelete()}>Delete label</MenuItem>
        <MenuItem onClick={sendToSf()}>Send to Salesforce</MenuItem>
      </Menu>
    </>
  );
}
