import Box from "@mui/material/Box";
import { IExplorerQuery } from "../explorer/ExplorerQuery";
import useQuery from "../../hooks/useQuery";
import { useDispatch, useSelector } from "react-redux";
import Paper from "@mui/material/Paper";
import { useForm } from "react-hook-form";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { classifyTable } from "./tableClassificationSlice";
import SpacingBox from "../SpacingBox";
import { useEffect, useMemo, useState } from "react";
import { RootState } from "../../store";
import Autocomplete from "@mui/material/Autocomplete";
import uniq from "lodash/uniq";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "../../hooks/useLocalStorage";
import { IconButton } from "@mui/material";
// copy icon
import { FileCopy, Spa } from "@mui/icons-material";
// paste icon
import { ContentPaste } from "@mui/icons-material";
import Joi from "joi";
import { tableToType } from "../../utils/tableToType";

interface IProps {
  handlePopperClose: () => void;
}

const websiteInputValidator = (value: string) => {
  if (value && value.split("\n").length < 20) {
    return "Must include at least 20 labels";
  } else if (!/^[a-z0-9\.\-\n]+$/.test(value)) {
    return "Only lowercased labels allowed";
  }
  return true;
};

export default function CountWordsForm(props: IProps) {
  const { query } = useQuery<IExplorerQuery>();
  const { tableId } = query;
  const dispatch = useDispatch();
  const isEntitiesTable = useMemo(
    () => tableToType(tableId) === "entities",
    [tableId]
  );
  const semanticsColumn = useMemo(
    () => (isEntitiesTable ? "context" : "semantic_keywords"),
    [isEntitiesTable]
  );

  const { goodCompanies, badCompanies } = useSelector(
    (state: RootState) => state.labels
  );
  const columns = useSelector((state: RootState) => [
    semanticsColumn,
    ...state.table.columns.map((c) => c.field),
  ]);
  const defaultColumns = getLocalStorageItem("classificationColumns") || [
    semanticsColumn,
  ];
  const defaultConsiderKeywords =
    getLocalStorageItem("considerKeywords") || false;
  const { register, getValues, setValue, errors, handleSubmit } = useForm<{
    goodLabels: string;
    badLabels: string;
    columnName: string;
    columns: string[];
  }>({
    defaultValues: {
      goodLabels: Object.keys(goodCompanies).join("\n"),
      badLabels: Object.keys(badCompanies).join("\n"),
      columns: defaultColumns,
    },
  });

  const [considerKeywords, setConsiderKeywords] = useState(
    defaultConsiderKeywords
  );

  useEffect(() => {
    register("columns");
  }, []);

  if (!tableId) {
    return null;
  }

  return (
    <Paper elevation={8}>
      <Box
        sx={{
          p: 2,
        }}
      >
        <Typography variant="h5">Binary classification</Typography>
      </Box>
      <Divider />
      <Box
        sx={{
          p: 2,
        }}
      >
        <Typography>
          List at least 20 websites (or ids) present in this table. Line-break
          separated.
        </Typography>
        <SpacingBox />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <TextField
            label={`Good labels (${Object.keys(goodCompanies).length})`}
            name="goodLabels"
            size="small"
            multiline
            rows={8}
            fullWidth
            inputRef={register({
              required: true,
              validate: websiteInputValidator,
            })}
            helperText={errors.goodLabels?.message}
            placeholder={`example.com\nexample.org\nexample.net`}
            error={!!errors.goodLabels}
          />

          <SpacingBox />
          <TextField
            label={`Bad labels (${Object.keys(badCompanies).length})`}
            name="badLabels"
            size="small"
            multiline
            rows={8}
            fullWidth
            inputRef={register({
              required: true,
              validate: websiteInputValidator,
            })}
            placeholder={`example.com\nexample.org\nexample.net`}
            helperText={errors.badLabels?.message}
            error={!!errors.badLabels}
          />
        </Box>

        <SpacingBox />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <TextField
            label="Classification name"
            name="columnName"
            size="small"
            sx={{ flex: 1 }}
            fullWidth
            defaultValue={query.theme}
            inputRef={register({
              required: true,
              pattern: /^[a-z0-9_]+$/,
              maxLength: 128,
              minLength: 5,
            })}
            placeholder={`my_awesome_classification`}
            error={!!errors.columnName}
          />
          <SpacingBox />
          <Tooltip title='If checked, then the classification model will use all counted keywords (columns starting with "_") in addition to the columns you selected.'>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Checkbox
                value={considerKeywords}
                checked={considerKeywords}
                onChange={(_, value) => {
                  setConsiderKeywords(value);
                  setLocalStorageItem("considerKeywords", value);
                }}
              />
              <Typography>Consider counted keywords</Typography>
            </Box>
          </Tooltip>
        </Box>
        <SpacingBox />
        <Autocomplete
          key={defaultColumns.join("")}
          multiple
          options={columns}
          size="small"
          onChange={(_, value) => {
            setValue("columns", value);
            setLocalStorageItem("classificationColumns", value);
          }}
          defaultValue={defaultColumns}
          filterSelectedOptions
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip label={option} {...getTagProps({ index })} />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select columns to consider"
              placeholder="Select columns to consider"
              helperText="WARNING: use only numeric and categorical columns. Plain text columns are not supported. The only exception is the website home page text and the semantic keywords column."
            />
          )}
        />
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          p: 1,
          justifyContent: "space-between",
        }}
      >
        <Button onClick={props.handlePopperClose}>Cancel</Button>
        <SpacingBox />

        <IconButton
          // use window.prompt to copy the current settings to clipboard
          // because clipboard API is not reliable
          onClick={() => {
            // considerKeywords, columns
            const settings = JSON.stringify({
              columns: getValues().columns,
              considerKeywords,
            });
            window.prompt("Copy to clipboard: Ctrl+C, Enter", settings);
          }}
        >
          <Tooltip title="Copy current settings to clipboard">
            <FileCopy />
          </Tooltip>
        </IconButton>
        <SpacingBox />
        <IconButton
          // use window.prompt to paste the settings from clipboard
          // because clipboard API is not reliable
          onClick={() => {
            const settings = window.prompt(
              "Paste settings from clipboard: Ctrl+V, Enter"
            );
            if (settings) {
              const parsed = validateAndParsePastedData(settings, columns);
              if (parsed) {
                setValue("columns", parsed.columns);
                setLocalStorageItem("classificationColumns", parsed.columns);
                setConsiderKeywords(parsed.considerKeywords);
                setLocalStorageItem(
                  "considerKeywords",
                  parsed.considerKeywords
                );
              }
            }
          }}
        >
          <Tooltip title="Paste settings from clipboard">
            <ContentPaste />
          </Tooltip>
        </IconButton>
        <Box sx={{ flex: 1 }} />
        <Button
          onClick={handleSubmit((data) => {
            if (data.columnName !== query.theme) {
              if (
                !window.confirm(
                  "The classification name is different from the theme name. This is an antipattern 👎. Are you sure?"
                )
              ) {
                return;
              }
            }
            const keywordsColumns = considerKeywords
              ? columns.filter((c) => c.startsWith("_"))
              : [];
            let selectedColumns = getValues().columns;
            if (typeof selectedColumns === "string") {
              selectedColumns = [selectedColumns as string];
            }
            const columnsToConsider = uniq(
              selectedColumns.concat(keywordsColumns)
            );
            dispatch(
              classifyTable(tableId, {
                ...data,
                columns: columnsToConsider,
                theme: query.theme,
              })
            );
            props.handlePopperClose();
          })}
        >
          Classify
        </Button>
      </Box>
    </Paper>
  );
}

// use Joi to validate the input
function validateAndParsePastedData(
  data: string,
  allAvailableColumns: string[]
) {
  try {
    const parsed = JSON.parse(data);
    // if a column is not available, then just ignore it
    const ignoredColumns: string[] = [];
    const columns = parsed.columns.filter((c: string) => {
      if (allAvailableColumns.includes(c)) {
        return true;
      } else {
        ignoredColumns.push(c);
        return false;
      }
    });
    if (ignoredColumns.length > 0) {
      alert(
        `The following columns are not available and gonna be ignored: ${ignoredColumns.join(
          ", "
        )}`
      );
    }
    if (typeof parsed.considerKeywords !== "boolean") {
      throw new Error("considerKeywords must be a boolean");
    }
    return {
      columns,
      considerKeywords: parsed.considerKeywords,
    };
  } catch (e) {
    alert(`Invalid data: ${e}`);
    return null;
  }
}
