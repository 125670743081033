import Box from "@mui/material/Box";
import { IExplorerQuery } from "../explorer/ExplorerQuery";
import useQuery from "../../hooks/useQuery";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import Paper from "@mui/material/Paper";
import { useForm } from "react-hook-form";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import snack from "../../utils/snack";
import { countWords } from "./countWordsSlice";
import uniq from "lodash/uniq";

interface IProps {
  handlePopperClose: () => void;
}
const PREFIX = "_t_";
const PREFIX_MENU = "_m_";
const PREFIX_REGEX = /^_t_|^_m_|^_/;

export default function CountWordsForm(props: IProps) {
  const { query } = useQuery<IExplorerQuery>();
  const { tableId } = query;
  const existingWords = useSelector((state: RootState) =>
    uniq(
      state.table.metadata?.schema.fields
        .filter(
          (field) => field.type === "INTEGER" && field.name.match(PREFIX_REGEX)
        )
        .map((field) => field.name.replace(PREFIX_REGEX, "").replace(/_/g, " "))
    ).join(", ")
  );
  const dispatch = useDispatch();
  const { register, errors, handleSubmit, setValue } = useForm<{
    words: string;
  }>({
    defaultValues: {
      words: existingWords,
    },
  });

  if (!tableId) {
    return null;
  }

  return (
    <Paper elevation={8}>
      <Box
        sx={{
          p: 2,
        }}
      >
        <Typography variant="h5">Count Words</Typography>
      </Box>
      <Divider />
      <Box
        sx={{
          p: 2,
        }}
      >
        <TextField
          label="List of words"
          name="words"
          size="small"
          maxRows={20}
          multiline
          minRows={3}
          fullWidth
          inputRef={register({
            required: true,
            pattern: /^[a-z0-9\- \,]+$/i,
          })}
          error={!!errors.words}
          placeholder="franchising, franchise, fdd, licensee..."
          helperText={
            errors.words
              ? "Allowed characters: a-z, 0-9, dash and space. Devide by comma."
              : null
          }
        />
      </Box>

      <Divider />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          p: 1,
          justifyContent: "space-between",
        }}
      >
        <Button onClick={props.handlePopperClose}>Cancel</Button>
        <Button
          onClick={handleSubmit((data) => {
            if (data.words === existingWords) {
              snack.warning(
                "The list of words is the same as before. Operation aborted."
              );
            } else {
              const words = uniq(
                data.words.split(",").map((word) => word.trim())
              );
              dispatch(countWords(tableId, { words, theme: query.theme }));
            }
            props.handlePopperClose();
          })}
        >
          Count
        </Button>
      </Box>
    </Paper>
  );
}
