import LoadingButton from "@mui/lab/LoadingButton";
import useQuery from "../../hooks/useQuery";
import { IExplorerQuery } from "../explorer/ExplorerQuery";
import { useDispatch, useSelector } from "react-redux";
import { sampleTable } from "./tableSlice";
import { RootState } from "../../store";
import Tooltip from "@mui/material/Tooltip";

export default function RandomSample() {
  const { query } = useQuery<IExplorerQuery>();
  const { tableId, theme } = query;
  const isLoading = useSelector(
    (state: RootState) => state.table.status === "loading"
  );
  const dispatch = useDispatch();
  const handleClick = () => {
    const percentage = Number(
      prompt(
        "Enter the percentage of the sample you want to take (from 0.001% to 100%)"
      )
    );
    if (!percentage) {
      return;
    }

    if (percentage < 0.001 || percentage > 100) {
      alert("Please enter a valid percentage");
      return;
    }
    if (!tableId) {
      return;
    }
    dispatch(
      sampleTable({
        tableId,
        percentage,
        theme,
      })
    );
  };
  return (
    <Tooltip title="Take a random sample of the table">
      <LoadingButton
        disabled={isLoading}
        loading={isLoading}
        onClick={handleClick}
      >
        Sample
      </LoadingButton>
    </Tooltip>
  );
}
