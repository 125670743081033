import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useLocalStorage from "../../hooks/useLocalStorage";
import descriptions from "./descriptions";
import Tooltip from "@mui/material/Tooltip";
import { useDispatch } from "react-redux";
import { downloadBatchData } from "./batchesSlice";
import SpacingBox from "../SpacingBox";

interface IProps {
  batchId: string;
  handleClose: () => void;
}

const keywordRegexp = /[^a-zA-Z0-9 ,-]/g;
const defaultGoodWords = [
  "franchisor",
  "franchisee",
  "franchising",
  "licensee",
  "licensing",
  "licensor",
  "royalty",
  "royalties",
  "locations",
  "units",
  "branches",
  "clinics",
  "stores",
  "centers",
  "offices",
  "sites",
  "dealer",
  "affiliate",
  "owner",
  "independent",
  "agents",
  "territory",
  "join",
  "owners",
  "dealers",
  "facilities",
  "affiliates",
  "geographies",
  "distribution",
  "members",
  "wellness",
  "global",
  "nationally",
  "network",
  "supply",
  "suppliers",
  "unit",
  "multi-unit",
  "nationwide",
  "nation",
  "states",
  "industry",
  "leader",
  "alliance",
  "partners",
  "partnership",
  "platform",
  "acquisitions",
  "acquiring",
  "companies",
  "holdings",
];

const defaultExcludedColumns = [
  "named_entities",
  "labels",
  "elastic_linkedin_also_viewed",
  "hq_locations",
  "hq_location",
  "db_categories",
  "names",
  "db_description",
  "investments",
  "fc_bio",
  "db_classification",
  "cb_description",
  "db_descriptors",
  "outscraper_description",
  "cb_category_sector",
  "cb_category_industryGroup",
  "cb_category_industry",
  "cb_category_subIndustry",
  "linkedin_industries",
  "elastic_linkedin_industry",
  "linkedin_specialties",
  "outscraper_type",
  "outscraper_category",
  "outscraper_subtypes",
  "fc_industries",
  "db_industries",
];

export default function KeywordsDialog(props: IProps) {
  const [goodWords, setGoodWords] = React.useState("");
  const [badWords, setBadWords] = React.useState("");
  const [selectedClusters, setClusters] = useLocalStorage(
    "selectedClusters",
    descriptions.map((_, i) => i)
  );
  const [excludedColumns, setExcludedColumns] = useLocalStorage(
    "excludedColumns",
    ""
  );
  const dispatch = useDispatch();
  const theme = useTheme();
  return (
    <Dialog open={!!props.batchId} onClose={props.handleClose}>
      <DialogTitle>
        Optionally, add comma-separated keywords to be counted.
      </DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Good words"
          fullWidth
          onChange={(e) => {
            setGoodWords(e.target.value.replace(keywordRegexp, ""));
          }}
          value={goodWords}
          multiline
          autoFocus
          onKeyPress={(e) => {
            if (!goodWords && e.key === "Enter") {
              e.preventDefault();
              setGoodWords(defaultGoodWords.join(","));
            }
          }}
          variant="outlined"
          placeholder="franchising,franchise,fdd,licensee. Press ENTER to add default keywords."
        />
        <TextField
          margin="dense"
          label="Bad words"
          fullWidth
          variant="outlined"
          onChange={(e) => {
            setBadWords(e.target.value.replace(keywordRegexp, ""));
          }}
          onKeyPress={(e) => {
            if (!badWords && e.key === "Enter") {
              e.preventDefault();
              setBadWords("dental,dentists");
            }
          }}
          multiline
          placeholder="dental,dentists... Press ENTER to add default keywords"
          value={badWords}
        />
        <TextField
          margin="dense"
          label="Excluded columns"
          fullWidth
          variant="outlined"
          onChange={(e) => {
            setExcludedColumns(e.target.value);
          }}
          onKeyPress={(e) => {
            if (!excludedColumns && e.key === "Enter") {
              e.preventDefault();
              setExcludedColumns(defaultExcludedColumns.join(","));
            }
          }}
          multiline
          placeholder={`${defaultExcludedColumns
            .slice(0, 2)
            .join(",")}... Press ENTER to add default keywords`}
          value={excludedColumns}
        />
        <Box>
          {descriptions.map((item, index) => (
            <Tooltip
              key={index}
              title={item}
              sx={{
                // show line breaks
                whiteSpace: "pre-line",
                maxWidth: 3000,
              }}
              // classes={{
              //   tooltip: classes.tooltip,
              // }}
            >
              <Button
                sx={{
                  marginRight: theme.spacing(1),
                  marginTop: theme.spacing(2),
                }}
                color="primary"
                onClick={() => {
                  if (selectedClusters.includes(index)) {
                    setClusters(selectedClusters.filter((i) => i !== index));
                  } else {
                    setClusters([...selectedClusters, index]);
                  }
                }}
                variant={
                  selectedClusters.includes(index) ? "contained" : "outlined"
                }
              >
                {index}
              </Button>
            </Tooltip>
          ))}
          <Button
            // open google.com in new tab
            onClick={() =>
              window.open(
                "https://docs.google.com/document/d/1cwRYRCY846tmx-vLRiFten-qmUIQevSVuXSMQGA4qII/edit?usp=sharing",
                "_blank"
              )
            }
            sx={{
              marginRight: theme.spacing(1),
              marginTop: theme.spacing(2),
            }}
          >
            Edit
          </Button>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>Cancel</Button>
        <Button
          onClick={() => {
            setGoodWords("");
            setBadWords("");
          }}
        >
          Reset form
        </Button>
        <SpacingBox />
        <Button
          onClick={() => {
            dispatch(
              downloadBatchData({
                batchId: props.batchId,
                keywords: parseWords(goodWords, badWords),
                clusters: selectedClusters,
                excludedColumns,
              })
            );
            props.handleClose();
          }}
        >
          Download
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function parseWords(good: string, bad: string) {
  const arr = good
    .split(",")
    .filter(Boolean)
    .map((item) => `good_${item.trim().toLowerCase()}`);

  bad
    .split(",")
    .filter(Boolean)
    .map((item) => arr.push(`bad_${item.trim().toLowerCase()}`));
  return arr.join(",");
}
