import Paper from "@mui/material/Paper";
import Scroller from "../scroller/Scroller";
import { DataGridPremium, useGridApiRef } from "@mui/x-data-grid-premium";
import { getOutreachMessages } from "./smsOutreachSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { SmsOutreachToolbar } from "./SmsOutreachToolbar";
import LinearProgress from "@mui/material/LinearProgress";

export default function SmsOutreachView() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOutreachMessages());
  }, []);
  const isLoading = useSelector(
    (state: RootState) => state.smsOutreach.status === "loading"
  );
  const rows = useSelector((state: RootState) =>
    state.smsOutreach.messages.map((item) => ({
      ...item,
      created_at: item.created_at ? new Date(item.created_at) : null,
      recipient_called_back_at: item.recipient_called_back_at
        ? new Date(item.recipient_called_back_at)
        : null,
      recipient_texted_back_at: item.recipient_texted_back_at
        ? new Date(item.recipient_texted_back_at)
        : null,
      name: item.metadata?.name,
      title: item.metadata?.title,
      company: item.metadata?.company,
      agent: item.metadata?.agent,
    }))
  );

  const apiRef = useGridApiRef();

  return (
    <Paper>
      <Scroller>
        <DataGridPremium
          rows={rows}
          apiRef={apiRef}
          hideFooter
          // checkboxSelection
          slots={{
            toolbar: () => <SmsOutreachToolbar />,
            loadingOverlay: LinearProgress,
          }}
          loading={isLoading}
          columns={[
            {
              field: "recipient_phone_number",
              headerName: "Recipient Phone",
              width: 130,
            },
            {
              field: "agent_phone_number",
              headerName: "Agent Phone",
              width: 130,
            },
            {
              field: "answered_text_followup",
              headerName: "Answered Text Followup",
              // width: 200,
            },
            {
              field: "not_answered_text_followup",
              headerName: "Not Answered Text Followup",
              // width: 200,
            },
            {
              field: "voicemail_url",
              headerName: "Voicemail URL",
              // width: 200,
            },
            {
              field: "voicemail_text_followup",
              headerName: "Voicemail Text Followup",
              // width: 200,
            },
            {
              field: "name",
              headerName: "Name",
            },
            {
              field: "title",
              headerName: "Title",
            },
            {
              field: "company",
              headerName: "Company",
              // width: 200,
            },
            {
              field: "agent",
              headerName: "Agent",
            },
            {
              field: "created_at",
              headerName: "Executed At",
              type: "date",
              // width: 200,
            },
            {
              field: "answered_by",
              headerName: "Answered By",
              // width: 200,
            },
            {
              field: "voicemail_sent",
              headerName: "Voicemail Sent",
              type: "boolean",
              // width: 200,
            },
            {
              field: "answered_text_followup_sent",
              headerName: "Answered Text Followup Sent",
              type: "boolean",
              // width: 200,
            },
            {
              field: "not_answered_text_followup_sent",
              headerName: "Not Answered Text Followup Sent",
              type: "boolean",
              // width: 200,
            },
            {
              field: "voicemail_text_followup_sent",
              headerName: "Voicemail Text Followup Sent",
              type: "boolean",
              // width: 200,
            },
            {
              field: "recipient_called_back_at",
              headerName: "Recipient Called Back At",
              type: "date",
              // width: 200,
            },
            {
              field: "recipient_texted_back_at",
              headerName: "Recipient Texted Back At",
              type: "date",
              // width: 200,
            },
            {
              field: "error",
              headerName: "Error",
              // width: 200,
            },
          ]}
        />
      </Scroller>
    </Paper>
  );
}
