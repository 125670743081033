import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Scroller from "../scroller/Scroller";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { SingleSelect } from "../select/Select";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { getBadWords, getBadWordsList, saveBadWords } from "./flagsSlice";
import Button from "@mui/material/Button";
import BadWordsBulkDialog from "./BadWordsBulkDialog";

export default function BadWordsForm() {
  const theme = useTheme();
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const badWordsList = useSelector(
    (state: RootState) => state.flagsSlice.badWordsList
  );
  const [selectedFlag, setSelectedFlag] = React.useState(badWordsList[0]);
  const badWords = useSelector(
    (state: RootState) => state.flagsSlice.badWordsByFlagName[selectedFlag]
  );
  const [isChanged, setChanged] = useState(false);
  useEffect(() => {
    dispatch(getBadWordsList());
  }, [dispatch]);
  useEffect(() => {
    selectedFlag && dispatch(getBadWords(selectedFlag));
  }, [dispatch, selectedFlag]);
  return (
    <Paper
      sx={{
        width: 375,
        maxWidth: 375,
      }}
    >
      <Box
        sx={{
          padding: theme.spacing(2),
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5">Bad words</Typography>
        <BadWordsBulkDialog />
        <Button
          size="small"
          disabled={!selectedFlag || !isChanged}
          variant="outlined"
          color="primary"
          onClick={handleSubmit((formData) => {
            dispatch(
              saveBadWords(selectedFlag, formData.badWords.toLowerCase())
            );
            setChanged(false);
          })}
        >
          Save
        </Button>
      </Box>
      <Divider />
      <Scroller>
        <Box
          sx={{
            padding: theme.spacing(2),
          }}
        >
          <SingleSelect
            noDefault
            label="Flag"
            options={badWordsList}
            defaultValue={selectedFlag}
            onSelect={(val) => {
              setSelectedFlag(val!);
              !!isChanged && setChanged(false);
            }}
          />
        </Box>
        <Divider />
        <Box
          sx={{
            padding: theme.spacing(2),
            display: "flex",
            flexDirection: "column",
            overflow: "auto",
          }}
        >
          {!!badWords && (
            <TextField
              onChange={() => !isChanged && setChanged(true)}
              multiline
              key={selectedFlag}
              label="Bad words"
              defaultValue={badWords}
              name="badWords"
              InputLabelProps={{ shrink: true }}
              inputRef={register({
                required: true,
              })}
              fullWidth
              size="small"
              variant="outlined"
            />
          )}
        </Box>
      </Scroller>
    </Paper>
  );
}
