import { GridToolbarContainer } from "@mui/x-data-grid/components/containers/GridToolbarContainer";
import Divider from "@mui/material/Divider";
import LoadingButton from "@mui/lab/LoadingButton";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";

import { Tooltip } from "@mui/material";
import snack from "../../utils/snack";
import {
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-premium";
import { deleteTables, getGbqTables } from "../tableSelect/tableSelectSlice";

interface IProps {
  getSelectedRows: () => string[];
}
export function TablesManagerToolbar(props: IProps) {
  const dispatch = useDispatch();
  return (
    <>
      <GridToolbarContainer sx={{ padding: 1 }}>
        <IconButton onClick={() => dispatch(getGbqTables())}>
          <RefreshIcon />
        </IconButton>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <Tooltip title="Irreversibly DELETE selected tables">
          <LoadingButton
            onClick={() => {
              const selectedRows = props.getSelectedRows();
              if (!selectedRows.length) {
                snack.error("No rows selected");
                return;
              }
              const tableName =
                window.prompt(
                  `Type exactly "DELETE ${selectedRows.length} TABLES" to confirm`
                ) || "";
              if (tableName !== `DELETE ${selectedRows.length} TABLES`) {
                return;
              } else {
                snack.info("😱 Deleting tables...");
              }
              dispatch(deleteTables(selectedRows, true));
            }}
          >
            Delete selected
          </LoadingButton>
        </Tooltip>
      </GridToolbarContainer>
      <Divider />
    </>
  );
}
