import snack from "./snack";

export async function selectFile() {
  var fileInput = document.createElement("input");
  fileInput.type = "file";
  fileInput.click();

  await new Promise((resolve) => {
    fileInput.onchange = resolve;
  });

  if (!fileInput.files?.[0]) {
    snack.error("You have to select a file to upload a file 🤔");
    return;
  }

  return new Promise((resolve) => {
    var reader = new FileReader();
    reader.onload = function () {
      const res = this.result as string;
      resolve(res);
    };
    reader.onerror = function (event) {
      snack.error(event.target?.error?.name || "Error selecting file");
      resolve("");
    };
    reader.readAsText(fileInput.files![0]);
  });
}
