import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'

interface IProps {
  size?: number
}
export default function SpacingBox (props: IProps) {
  const theme = useTheme()
  const size = props.size || 2
  return (
    <Box
      sx={{
        width: theme.spacing(size),
        height: theme.spacing(size),
        minWidth: theme.spacing(size),
        minHeight: theme.spacing(size),
        [theme.breakpoints.down('xs')]: {
          width: theme.spacing(size / 2),
          height: theme.spacing(size / 2),
          minWidth: theme.spacing(size / 2),
          minHeight: theme.spacing(size / 2)
        }
      }}
    />
  )
}
