import Box from "@mui/material/Box";

import PipelineDescription from "./PipelineDescription";
import PipelineDiagram from "./PipelineDiagram";
import SpacingBox from "../SpacingBox";
import { HorizontalScroller } from "../scroller/Scroller";

export default function PipelineView() {
  return (
    <HorizontalScroller>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <PipelineDiagram />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <SpacingBox />
        <PipelineDescription />
      </Box>
    </HorizontalScroller>
  );
}
