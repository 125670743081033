import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SpacingBox from "../SpacingBox";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";

import { bulkRemoveBadWords, IFlagsDict } from "./flagsSlice";

export default function BadWordsBulkDialog() {
  const [isOpen, setOpen] = React.useState(false);
  const [inputWords, setInputWords] = React.useState("");
  const [flagsDict, setFlagsDict] = React.useState<IFlagsDict | undefined>(
    undefined
  );
  const isReady = useSelector(
    (state: RootState) => state.flagsSlice.status === "ready"
  );
  const badWordsList = useSelector(
    (state: RootState) => state.flagsSlice.badWordsList
  );
  const dispatch = useDispatch();
  return (
    <>
      <Button variant="outlined" size="small" onClick={() => setOpen(true)}>
        Bulk remove
      </Button>
      <Dialog open={isOpen} onClose={() => setOpen(false)}>
        <DialogTitle>
          {flagsDict
            ? "Confirm flags to remove"
            : "Paste a list of bad words to be removed"}
        </DialogTitle>
        <Divider />
        <DialogContent
          sx={{
            minWidth: 600,
          }}
        >
          {flagsDict ? (
            Object.keys(flagsDict).map((flagId) => (
              <>
                <Typography variant="h6">
                  {!badWordsList.includes(flagId)
                    ? "🔴 [FLAG NOT FOUND] "
                    : "🟢 "}
                  {flagId}
                </Typography>
                {flagsDict[flagId].map((word) => (
                  <Typography>{word}</Typography>
                ))}
                <SpacingBox />
              </>
            ))
          ) : (
            <TextField
              margin="dense"
              label=""
              fullWidth
              maxRows={10}
              variant="outlined"
              onChange={(e) => setInputWords(e.target.value?.toLowerCase())}
              multiline
              placeholder={`clearbit_bad_sub_industries:utilities\ndescription_bad_words:accessories\ndescription_bad_words:construction,real estate,roofing contractors\n...`}
              value={inputWords}
            />
          )}
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
              setFlagsDict(undefined);
              setInputWords("");
            }}
          >
            Close
          </Button>
          <Button
            onClick={() => {
              setInputWords("");
              setFlagsDict(undefined);
            }}
          >
            Reset form
          </Button>
          <SpacingBox />
          {flagsDict ? (
            <Button
              disabled={!isReady}
              onClick={() => {
                dispatch(bulkRemoveBadWords(flagsDict));
              }}
            >
              Confirm
            </Button>
          ) : (
            <Button
              onClick={() => {
                setFlagsDict(parseWords(inputWords));
              }}
            >
              Submit
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

function parseWords(inputWords: string) {
  const lines = inputWords.split("\n");
  const dict: { [flagId: string]: string[] } = {};
  for (const line of lines) {
    const [flagId, words] = line.split(":");
    if (flagId && words) {
      if (!dict[flagId]) {
        dict[flagId] = [];
      }
      for (const word of words.split(",")) {
        if (dict[flagId].includes(word)) {
          continue;
        } else {
          dict[flagId].push(word);
        }
      }
    }
  }
  return dict;
}
