import {
  configureStore,
  getDefaultMiddleware,
  ThunkAction,
  Action,
  combineReducers,
} from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import dataManagerSlice from "./features/dataManager/dataManagerSlice";
import batchesSlice from "./features/batches/batchesSlice";

import flagsSlice from "./features/flagsSettings/flagsSlice";
import sessionSlice from "./wrapper/sessionSlice";
import tableSelectSlice from "./features/tableSelect/tableSelectSlice";
import tableSlice from "./features/table/tableSlice";
import semanticSearchSlice from "./features/semanticSearch/semanticSearchSlice";
import countWordsSlice from "./features/countWords/countWordsSlice";
import tableClassificationSlice from "./features/tableClassification/tableClassificationSlice";
import smsOutreachSlice from "./features/smsOutreach/smsOutreachSlice";
import newspapersSlice from "./features/newspapers/newspapersSlice";
import labelsSlice from "./features/swiper/labelsSlice";
import modelsSlice from "./features/modelGarden/modelsSlice";
import phoneMaskingSlice from "./features/phoneMasking/phoneMaskingSlice";
import tableClusteringSlice from "./features/tableClustering/tableClusteringSlice";
import usersSlice from "./features/users/usersSlice";

const rootReducer = combineReducers({
  session: sessionSlice,
  dataManager: dataManagerSlice,
  batches: batchesSlice,
  // companiesWarehouse,
  // companiesTable: companiesTableSlice,
  // companyDetails: companyDetailsSlice,
  flagsSlice: flagsSlice,
  tableSelect: tableSelectSlice,
  table: tableSlice,
  semanticSearch: semanticSearchSlice,
  countWords: countWordsSlice,
  tableClassification: tableClassificationSlice,
  smsOutreach: smsOutreachSlice,
  newspapers: newspapersSlice,
  labels: labelsSlice,
  models: modelsSlice,
  phoneMasking: phoneMaskingSlice,
  tableClustering: tableClusteringSlice,
  users: usersSlice,
});

const persistedReducer = persistReducer(
  {
    key: "root",
    version: 1,
    storage,
    whitelist: ["session"],
  },
  rootReducer
);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
