import Paper from "@mui/material/Paper";
import { ReactComponent as Diagram } from "./pipeline.svg";
import Scroller from "../scroller/Scroller";
import Box from "@mui/material/Box";

export default function PipelineDiagram() {
  return (
    <Paper>
      <Scroller>
        <Box
          sx={{
            "&::-webkitScrollbar": {
              display: "none",
            },
            MsOverflowStyle: "none",
            scrollbarWidth: "none",
            overflowY: "auto",
            height: "100%",
            position: "relative",
          }}
        >
          <Diagram />
        </Box>
      </Scroller>
    </Paper>
  );
}
