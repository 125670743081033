export function downloadString(fileName: string, content: string) {
  var element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:data:application/octet-stream;charset=utf-8," +
      encodeURIComponent(content)
  );
  element.setAttribute("download", fileName);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}
