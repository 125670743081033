import Paper from "@mui/material/Paper";
import Scroller from "../scroller/Scroller";
import {
  DataGridPremium,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { TablesManagerToolbar } from "./TablesManagerToolbar";
import LinearProgress from "@mui/material/LinearProgress";
import moment from "moment";
import { getGbqTables } from "../tableSelect/tableSelectSlice";
import TextCell from "../textCell/TextCell";
import TableLinkCell from "./TableLinkCell";
import SaveTablePopper from "../saveTablePopper/SaveTablePopper";
import ColorCodedCell from "../colorCodedCell/ColorCodedCell";

const dateThreshold = Date.now() + 1000 * 60 * 60 * 24 * 7;
const sizeThreshold = 1e8;

export default function TableManagerView() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getGbqTables());
  }, []);
  const isLoading = useSelector(
    (state: RootState) => state.newspapers.status === "loading"
  );
  const rows = useSelector((state: RootState) =>
    state.tableSelect.tablesIds.map((id) => {
      const t = state.tableSelect.tablesById[id];
      return {
        id,
        theme: t.theme,
        "table.id": t.table.id,
        created_at: t.created_at,
        "created_by.name": t.created_by?.name,
        expiration_time: t.expiration_time,
        "parent_table.id": t.parent_table?.id,
        rows_count: t.rows_count,
        size_bytes: t.size_bytes,
        query: t.query,
        table_metadata: t.table_metadata
          ? JSON.stringify(t.table_metadata, null, 2)
          : null,
      };
    })
  );

  const apiRef = useGridApiRef();

  const getSelectedRows = () =>
    Array.from(apiRef.current?.getSelectedRows())
      .map((item) => item[1]?.id)
      .filter(Boolean) || [];

  return (
    <Paper>
      <Scroller>
        <DataGridPremium
          rows={rows}
          apiRef={apiRef}
          // hideFooter
          disableRowSelectionOnClick
          checkboxSelection
          getRowId={(row) => row.id}
          slots={{
            toolbar: () => (
              <TablesManagerToolbar getSelectedRows={getSelectedRows} />
            ),
            loadingOverlay: LinearProgress,
          }}
          loading={isLoading}
          unstable_headerFilters
          // density="compact"
          pinnedColumns={{
            left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, "table.id"],
          }}
          columns={[
            {
              field: "table.id",
              headerName: "table_id",
              renderCell: (props) => <TableLinkCell {...props} />,
              width: 500,
            },
            {
              field: "created_by.name",
              headerName: "created_by",
              width: 200,
            },
            {
              field: "created_at",
              headerName: "created_at",
              type: "date",
              width: 200,
              valueFormatter: ({ value }) =>
                value ? moment(value).fromNow() : null,
            },
            {
              field: "theme",
              headerName: "theme",
              width: 200,
            },
            {
              field: "expiration_time",
              headerName: "expiration_time",
              renderCell: (props) => (
                <ColorCodedCell
                  {...props}
                  color={(props.value as number) < dateThreshold ? "red" : ""}
                />
              ),
              valueFormatter: ({ value }) =>
                value ? moment(value).fromNow() : null,
              width: 200,
            },
            {
              field: "parent_table.id",
              headerName: "parent",
              valueFormatter: ({ value }) => value?.split(".").pop(),
              width: 200,
            },
            {
              field: "rows_count",
              headerName: "rows_count",
              width: 200,
            },
            {
              field: "size_bytes",
              headerName: "size",
              width: 200,
              valueFormatter: ({ value }) =>
                `${(value / 1000000).toFixed(2)} MB`,
              renderCell: (props) => (
                <ColorCodedCell
                  {...props}
                  color={(props.value as number) > sizeThreshold ? "red" : ""}
                />
              ),
            },
            {
              field: "query",
              headerName: "query",
              width: 200,
              renderCell: (props) => <TextCell {...props} />,
            },
            {
              field: "table_metadata",
              headerName: "table_metadata",
              width: 200,
              renderCell: (props) => <TextCell {...props} />,
            },
            {
              field: "actions",
              type: "actions",
              getActions: (params) => [
                <SaveTablePopper
                  tableId={params.id as string}
                  disableTooltip
                />,
              ],
            },
          ]}
        />
      </Scroller>
    </Paper>
  );
}
