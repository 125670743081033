import { GridToolbarContainer } from "@mui/x-data-grid/components/containers/GridToolbarContainer";
import { GridToolbarColumnsButton } from "@mui/x-data-grid/components/toolbar/GridToolbarColumnsButton";
import { GridToolbarFilterButton } from "@mui/x-data-grid/components/toolbar/GridToolbarFilterButton";

import Divider from "@mui/material/Divider";
import Box from "@mui/system/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import CountWordsPoper from "../countWords/CountWordsPopper";
import TableClassificationPopper from "../tableClassification/TableClassificationPopper";
import { Tooltip } from "@mui/material";
import { TableClustering } from "../tableClustering/TableClustering";
import TableDownload from "./TableDownload";
import ThemeSelect from "../theme/ThemeSelect";
import TableLabelMenu from "./TableLabelMenu";
import { LostLabels } from "./TableLostLabels";
import RandomSample from "./RandomSample";

interface IProps {
  handleSubmit: () => void;
  canSubmit: boolean;
  handleRefresh: () => void;
  getSelectedRows: () => string[];
}

export function TableToolbar(props: IProps) {
  const isLoading = useSelector(
    (state: RootState) => state.table.status === "loading"
  );
  return (
    <>
      <GridToolbarContainer sx={{ padding: 1 }}>
        <ThemeSelect />
        <TableLabelMenu getSelectedRows={props.getSelectedRows} />
        <Divider orientation="vertical" flexItem />
        <Tooltip title="Refresh table data">
          <span>
            <IconButton disabled={isLoading} onClick={props.handleRefresh}>
              <RefreshIcon />
            </IconButton>
          </span>
        </Tooltip>
        <GridToolbarColumnsButton />
        <Divider orientation="vertical" flexItem />
        <GridToolbarFilterButton />
        <LostLabels />
        <Tooltip title="Apply filters and/or sorting">
          <span>
            <Button
              // variant="outlined"
              disabled={!props.canSubmit}
              onClick={props.handleSubmit}
            >
              Apply
            </Button>
          </span>
        </Tooltip>
        <Box sx={{ flexGrow: 1 }} />
        <RandomSample />
        <CountWordsPoper />
        <TableClassificationPopper />
        <TableClustering />
        <TableDownload />
      </GridToolbarContainer>
      <Divider />
    </>
  );
}
