import { LoadingButton } from "@mui/lab";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import { deleteTables } from "./tableSelectSlice";
import { RootState } from "../../store";
import { Tooltip } from "@mui/material";
import useQuery from "../../hooks/useQuery";
import { IExplorerQuery } from "../explorer/ExplorerQuery";

export default function TableSelectDelete() {
  const dispatch = useDispatch();
  const isLoading = useSelector(
    (state: RootState) => state.tableSelect.status === "loading"
  );
  const { query } = useQuery<IExplorerQuery>();
  const { tableId } = query;
  const selectedTable = useSelector((state: RootState) =>
    tableId ? state.tableSelect.tablesById[tableId] : undefined
  );

  return (
    <Tooltip title="Delete this table (⚠️ IRREVERSIBLE)">
      <span>
        <LoadingButton
          disabled={!selectedTable || isLoading}
          onClick={() => {
            if (
              window.prompt(
                `Are you sure you want to delete table "${selectedTable?.table.table_id}"? Type \"DELETE\"`
              ) === "DELETE"
            ) {
              dispatch(deleteTables([tableId!]));
            }
          }}
        >
          <DeleteIcon />
        </LoadingButton>
      </span>
    </Tooltip>
  );
}
