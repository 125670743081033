import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Scroller from "../scroller/Scroller";
import Tooltip from "@mui/material/Tooltip";

const infoPanelWidth = 500;
const keyWidth = 180;
const valueWidth = infoPanelWidth - keyWidth;

interface IProps {
  company: { [key: string]: any };
}

export default function SwiperDetails(props: IProps) {
  const { company } = props;

  return (
    <Paper
      sx={{
        flex: 0,
        width: infoPanelWidth,
        minWidth: infoPanelWidth,
        maxWidth: infoPanelWidth,
      }}
    >
      <Scroller>
        <Box
          sx={{
            flex: 1,
            // hidden scrollbar
            overflowY: "auto",
            "&::-webkitScrollbar": {
              display: "none",
            },
            MsOverflowStyle: "none",
            scrollbarWidth: "none",
            position: "relative",
          }}
        >
          <Table size="small">
            {Object.keys(company).map((key) => (
              <TableRow>
                <TableCell>
                  {key.length > 25 ? (
                    <Tooltip title={key}>
                      <Typography
                        sx={{
                          width: keyWidth,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                        variant="body2"
                      >
                        {key}
                      </Typography>
                    </Tooltip>
                  ) : (
                    <Typography
                      sx={{
                        width: keyWidth,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      variant="body2"
                    >
                      {key}
                      {key === "website" ? " (ENTER to open)" : ""}
                    </Typography>
                  )}
                </TableCell>
                <TableCell width={valueWidth}>
                  <Typography
                    variant="body2"
                    sx={{
                      wordBreak: "break-all",
                      overflowWrap: "break-word",
                    }}
                  >
                    {company[key]}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </Box>
      </Scroller>
    </Paper>
  );
}
