import Paper from "@mui/material/Paper";
import SpacingBox from "../SpacingBox";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {
  IPhoneMask,
  getPhoneMasks,
  upsertPhoneMask,
} from "./phoneMaskingSlice";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import PhoneMaskForm from "./PhoneMaskForm";
import { useEffect } from "react";
import { RootState } from "../../store";

export default function PhoneMaskingView() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPhoneMasks());
  }, []);
  const phoneMasks = useSelector(
    (state: RootState) => state.phoneMasking.masks
  );
  return (
    <Paper sx={{ maxWidth: 600 }}>
      <Box sx={{ padding: 2 }}>
        <Typography variant="h6">Phone masking</Typography>
        <Typography variant="caption">
          Texts and calls made by agent to broker will be forwarded to
          recipient.
        </Typography>
      </Box>
      <Divider />
      {phoneMasks.map((mask) => (
        <PhoneMaskForm {...mask} />
      ))}
    </Paper>
  );
}
