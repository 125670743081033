import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { HorizontalScroller } from "../scroller/Scroller";

const data = [
  [
    "line_key",
    "organic_keywords",
    "hq_location",
    "distinct_values_count",
    "",
    "elastic_google_bad_keywords",
    "nation",
  ],
  [
    "line_errors",
    "organic_traffic",
    "country",
    "present_in_elastic",
    "",
    "elastic_linkedin_bad_overview",
    "nationally",
  ],
  [
    "linkedin",
    "outscraper_type",
    "elastic_linkedin_state",
    "present_in_salesforce",
    "",
    "elastic_linkedin_bad_types",
    "franchisor",
  ],
  [
    "website",
    "outscraper_category",
    "hq_locations",
    "company_name_ends_with",
    "",
    "elastic_yelp_bad_history",
    "wellness",
  ],
  [
    "name",
    "outscraper_subtypes",
    "outscraper_coordinates",
    "company_name_starts_with",
    "",
    "elastic_linkedin_bad_industries",
    "multiunit",
  ],
  [
    "names",
    "elastic_linkedin_industry",
    "elastic_linkedin_overview",
    "company_name_bad_words",
    "",
    "linkedin_bad_industries",
    "geographies",
  ],
  [
    "flags",
    "sic_codes",
    "cb_description",
    "company_name_contains",
    "",
    "linkedin_small",
    "territories",
  ],
  [
    "distinct_flags",
    "naics_codes",
    "db_description",
    "company_name_gender",
    "",
    "elastic_yelp_bad_specialty",
    "network",
  ],
  [
    "score_per_flag",
    "db_industries",
    "outscraper_description",
    "bad_domain",
    "",
    "elastic_linkedin_over_12_years_old",
    "distribution",
  ],
  [
    "total_score",
    "cb_category_sector",
    "linkedin_about",
    "gov_or_edu",
    "",
    "elastic_linkedin_bad_specialties",
    "royalty",
  ],
  [
    "flags_count",
    "cb_category_industryGroup",
    "linkedin_slogan",
    "outscraper_description_bad_words",
    "",
    "linkedin_bad_specialties",
    "royalties",
  ],
  [
    "flag_types_count",
    "cb_category_industry",
    "outscraper_about",
    "bad_domain_extension",
    "",
    "elastic_google_bad_search_keyword",
    "licensing",
  ],
  [
    "keywords",
    "cb_category_subIndustry",
    "db_summary",
    "outscraper_bad_category",
    "",
    "elastic_yelp_bad_categories",
    "_franchisor",
  ],
  [
    "number_of_locations",
    "linkedin_industries",
    "fc_bio",
    "description_bad_words",
    "",
    "elastic_yelp_over_12_years_old",
    "_franchisee",
  ],
  [
    "distinct_states_count",
    "db_classification",
    "elastic_linkedin_type",
    "subdomain",
    "",
    "elastic_google_bad_keywords",
    "_franchising",
  ],
  [
    "growth_signal",
    "db_descriptors",
    "elastic_linkedin_specialties",
    "us_cities_bad_words",
    "",
    "elastic_linkedin_bad_overview",
    "_licensee",
  ],
  [
    "semrush_rank",
    "db_categories",
    "linkedin_specialties",
    "menu_bad_words",
    "",
    "elastic_linkedin_bad_types",
    "_licensing",
  ],
  [
    "words_count",
    "fc_industries",
    "elastic_linkedin_also_viewed",
    "menu_very_bad_words",
    "",
    "elastic_google_non_us",
    "_licensor",
  ],
  [
    "distinct_words_count",
    "linkedin_sphere",
    "emails",
    "po_box_in_address",
    "",
    "elastic_linkedin_non_us",
    "_royalty",
  ],
  [
    "distinct_good_words",
    "elastic_linkedin_size",
    "linkedin_locations",
    "phone_or_email_in_header",
    "",
    "elastic_yelp_over_12_years_old",
    "_royalties",
  ],
  [
    "distinct_bad_words",
    "linkedin_size",
    "fc_locations_count",
    "street_view",
    "",
    "elastic_yelp_bad_categories",
    "_locations",
  ],
  [
    "good_words_total_count",
    "employees_count_max",
    "db_locations_count",
    "outscraper_verified",
    "",
    "clearbit_bad_sub_industries",
    "_units",
  ],
  [
    "bad_words_total_count",
    "employees_count_cb",
    "cb_geo_street_number",
    "outscraper_reviews",
    "",
    "clearbit_over_12_years_old",
    "_clinics",
  ],
  [
    "first_discovered",
    "employees_count_fc",
    "cb_geo_street_name",
    "outscraper_rating",
    "",
    "over_12_years_old",
    "_stores",
  ],
  [
    "last_discovered",
    "employees_count_db",
    "cb_geo_street_address",
    "db_revenue",
    "",
    "keywords_bad_words",
    "_centers",
  ],
  [
    "screenshot_taken",
    "employees_count_elastic",
    "cb_domainAliases",
    "db_is_acquired",
    "",
    "keywords_very_bad_words",
    "_offices",
  ],
  [
    "states",
    "employees_count_linkedin",
    "cb_site_phone_numbers",
    "db_founders",
    "",
    "gender_bad_words",
    "_sites",
  ],
  [
    "labels",
    "linkedin_employee_count",
    "twitter",
    "ceoName",
    "",
    "fc_bad_industries",
    "_dealer",
  ],
  [
    "founded",
    "elastic_employees_count",
    "elastic_linkedin_followers",
    "investments",
    "",
    "fc_over_8_years_old",
    "_affiliate",
  ],
  [
    "max_website_year",
    "twitter_followers",
    "db_founders",
    "db_all_uris",
    "",
    "fc_over_12_years_old",
    "_owner",
  ],
  [
    "max_sitemap_year",
    "website_traffic",
    "ceoName",
    "",
    "",
    "fc_non_us",
    "_independent",
  ],
  [
    "metrics_raised",
    "adwords_keywords",
    "investments",
    "",
    "",
    "diffbot_bad_industries",
    "_agents",
  ],
  [
    "cb_annual_revenue",
    "adwords_traffic",
    "db_all_uris",
    "",
    "",
    "diffbot_bad_descriptors",
    "_territory",
  ],
  ["", "adwords_cost", "organic_cost", "", "", "clearbit_non_us", "_join"],
  ["", "", "", "", "", "clearbit_non_private", "_owners"],
  ["", "", "", "", "", "clearbit_over_8_years_old", "_dealers"],
  ["", "", "", "", "", "", "_facilities"],
  ["", "", "", "", "", "", "_affiliates"],
  ["", "", "", "", "", "", "_geographies"],
  ["", "", "", "", "", "", "_distribution"],
  ["", "", "", "", "", "", "_members"],
  ["", "", "", "", "", "", "_wellness"],
  ["", "", "", "", "", "", "_global"],
  ["", "", "", "", "", "", "_nationally"],
  ["", "", "", "", "", "", "_network"],
  ["", "", "", "", "", "", "_supply"],
  ["", "", "", "", "", "", "_suppliers"],
  ["", "", "", "", "", "", "_unit"],
  ["", "", "", "", "", "", "_multi_unit"],
  ["", "", "", "", "", "", "_nationwide"],
  ["", "", "", "", "", "", "_nation"],
  ["", "", "", "", "", "", "_states"],
  ["", "", "", "", "", "", "_industry"],
  ["", "", "", "", "", "", "_leader"],
  ["", "", "", "", "", "", "_alliance"],
  ["", "", "", "", "", "", "_partners"],
  ["", "", "", "", "", "", "_partnership"],
  ["", "", "", "", "", "", "_platform"],
  ["", "", "", "", "", "", "_acquisitions"],
  ["", "", "", "", "", "", "_acquiring"],
  ["", "", "", "", "", "", "_companies"],
  ["", "", "", "", "", "", "_holdings"],
];

export default function AttributesView() {
  return (
    <HorizontalScroller>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableBody>
            {data.map((row) => (
              <TableRow key={"r" + row[0]}>
                {row.map((item) => (
                  <TableCell>{item}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </HorizontalScroller>
  );
}
