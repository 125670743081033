import Paper from "@mui/material/Paper";
import green from "@mui/material/colors/green";
import red from "@mui/material/colors/red";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Scroller from "../scroller/Scroller";
import orange from "@mui/material/colors/orange";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Button from "@mui/material/Button";
import SpacingBox from "../SpacingBox";

export default function PipelineDescription() {
  const theme = useTheme();
  const stats = [
    {
      label: "Past 24 hours workload",
      value: "36,858",
    },
    {
      label: "Past 24 hours errors",
      value: "146",
      color: red[600],
    },
    {
      label: "Past 24 hours disqualifications",
      value: "1,045",
      color: red[600],
    },
    {
      label: "Past 24 hours duplicates",
      value: "32,632",
      color: red[600],
    },
    {
      label: "Past 24 hours sucessfully completed",
      value: "3,035",
      color: green[600],
    },
    {
      label: "Lifetime items processed",
      value: "4,278,743",
    },
    {
      label: "Lifetime duplicates omitted",
      value: "1,832,129",
    },
    {
      label: "Max throughput",
      value: "600k per 24h",
    },
  ];
  return (
    <Paper
      sx={{
        maxWidth: 375,
        width: 375,
        minWidth: 375,
      }}
    >
      <Box
        sx={{
          padding: theme.spacing(2),
          flexDirection: "row",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            color: orange[600],
          }}
        >
          Resolve company name
        </Typography>
      </Box>
      <Divider />
      <Scroller additionalOffset={69.5}>
        <Box
          sx={{
            "&::-webkitScrollbar": {
              display: "none",
            },
            MsOverflowStyle: "none",
            scrollbarWidth: "none",
            overflowY: "auto",
            height: "100%",
            position: "relative",
          }}
        >
          <Box
            sx={{
              padding: theme.spacing(2),
            }}
          >
            <Typography>
              Accepts company name. Scrapes relevant info from Bing search
              results page and sidebar.
            </Typography>
          </Box>
          <Table>
            <TableBody>
              {stats.map((row) => (
                <TableRow key={row.label}>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    {row.label}:
                  </TableCell>
                  <TableCell
                    style={{ color: row.color }}
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    {row.value}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Scroller>
      <Divider />
      <Box
        sx={{
          padding: theme.spacing(2),
          flexDirection: "row",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          disabled={false}
          onClick={() => {}}
          variant="outlined"
          fullWidth
        >
          Stage report
        </Button>
        <SpacingBox />
        <Button
          disabled={false}
          onClick={() => {}}
          variant="outlined"
          fullWidth
        >
          Purge queue
        </Button>
      </Box>
    </Paper>
  );
}
