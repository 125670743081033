import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import moment from "moment";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/CloudDownload";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import lottie from "lottie-web";
import Tooltip from "@mui/material/Tooltip";
import {
  downloadBatchData,
  postToClay,
  downloadBatchScreenshots,
  getBatches,
  downloadImages,
} from "./batchesSlice";
import { batchesByKey } from "./batchesDict";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import KeywordsDialog from "./KeywordsDialog";
import Scroller from "../scroller/Scroller";
import Skeleton from "@mui/material/Skeleton";
import useQuery from "../../hooks/useQuery";
import { IBatchesQuery } from "./batchesQuery";
import BatchesFilters from "./BatchesFilters";
import { LinearProgress } from "@mui/material";

const statusIcons: { [batchStatus: string]: string } = {
  Submitted: "⬜️",
  "In progress": "🟧",
  Completed: "✅",
};
export default function BatchesList() {
  const { query } = useQuery<IBatchesQuery>();

  const [dialogBatch, setDialogBatch] = useState("");
  const theme = useTheme();

  const batches = useSelector((state: RootState) =>
    Object.keys(state.batches.batchesByName)
      .map((batchName) => {
        const createdAt = moment(
          parseInt(
            state.batches.batchesByName[batchName]._id.substring(0, 8),
            16
          ) * 1000
        );
        const period = createdAt.format("YYYY-MM");
        return {
          ...state.batches.batchesByName[batchName],
          period,
          createdAt: createdAt.format("MMM Do h:mm a"),
        };
      })
      .reverse()
  );

  const allBatchesIds = useSelector((state: RootState) =>
    Object.keys(state.batches.batchesByName)
  );

  const dispatch = useDispatch();
  const isLoading = useSelector(
    (state: RootState) => state.batches.status === "loading"
  );
  useEffect(() => {
    dispatch(getBatches(query));
  }, [
    dispatch,
    query.period,
    query.batchType,
    query.searchPhrase,
    query.notDownloadedOnly,
  ]);

  return (
    <Paper
      sx={{
        minWidth: 550,
        width: 550,
      }}
    >
      <BatchesFilters />
      <Divider />
      <LinearProgress
        sx={{
          visibility: isLoading ? "visible" : "hidden",
        }}
      />
      <Scroller>
        <List
          sx={{
            "&::-webkitScrollbar": {
              display: "none",
            },
            MsOverflowStyle: "none",
            scrollbarWidth: "none",
            overflowY: "auto",
            height: "100%",
          }}
          dense
        >
          {!allBatchesIds.length &&
            isLoading &&
            Array(8)
              .fill(undefined)
              .map((_, index) => (
                <ListItem key={`slControlsSkeletonList${index}`}>
                  <ListItemText
                    primary={
                      <>
                        <Skeleton height={20} width={170} />
                      </>
                    }
                    secondary={
                      <Box>
                        <Skeleton height={20} width={141} />
                        <Skeleton height={20} width={195} />
                        <Skeleton height={20} width={135} />
                      </Box>
                    }
                  />
                  <ListItemSecondaryAction
                    sx={{
                      flexDirection: "row",
                      display: "flex",
                    }}
                  >
                    {Array(Math.random() > 0.66 ? 2 : 3)
                      .fill(undefined)
                      .map((_, index) => (
                        <Skeleton
                          key={index}
                          sx={{
                            marginLeft: theme.spacing(2),
                          }}
                          height={34}
                          width={34}
                        />
                      ))}
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
          {!batches.length && !isLoading && <NothingHere />}
          {batches.map((item) => (
            <ListItem key={item.batchName + "final"}>
              <ListItemText
                primary={statusIcons[item.batchStatus] + " " + item.batchName}
                secondary={
                  <>
                    <span>
                      {batchesByKey[item.batchType].selectLabel}
                      {item.batchSize
                        ? ` (${new Intl.NumberFormat().format(item.batchSize)})`
                        : ""}
                    </span>
                    <br />
                    {!!item.batchFlags && (
                      <>
                        <span>{item.batchFlags}</span>
                        <br />
                      </>
                    )}
                    <span>{item.statusMessage || item.batchStatus}</span>
                    <br />
                    <span>Submitted: {item.createdAt}</span>
                    {!!item.downloadedBy?.length && (
                      <>
                        <br />
                        <span>
                          Downloaded by: {item.downloadedBy?.join(", ")}
                        </span>
                      </>
                    )}
                  </>
                }
              />
              <ListItemSecondaryAction>
                {!!batchesByKey[item.batchType].canPushToClay && (
                  <Tooltip
                    sx={{
                      marginLeft: theme.spacing(2),
                    }}
                    title={"Push to Clay"}
                  >
                    <IconButton
                      edge="end"
                      onClick={() => {
                        dispatch(postToClay(item._id));
                      }}
                    >
                      <FileUploadIcon />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip
                  sx={{
                    marginLeft: theme.spacing(2),
                  }}
                  title={"Download batch images"}
                >
                  <IconButton
                    edge="end"
                    onClick={() => {
                      item.batchType === "images"
                        ? dispatch(downloadImages({ batchId: item._id }))
                        : dispatch(
                            downloadBatchScreenshots({ batchId: item._id })
                          );
                    }}
                  >
                    <PhotoLibraryIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  sx={{
                    marginLeft: theme.spacing(2),
                  }}
                  title={"Download full batch data"}
                >
                  <IconButton
                    edge="end"
                    onClick={() => {
                      if (
                        ![
                          "people",
                          "screenshots",
                          "coresignalPeople",
                          "coresignalCompanies",
                        ].includes(item.batchType)
                      ) {
                        setDialogBatch(item._id);
                      } else {
                        dispatch(downloadBatchData({ batchId: item._id }));
                      }
                    }}
                  >
                    <DownloadIcon />
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Scroller>
      <KeywordsDialog
        handleClose={() => setDialogBatch("")}
        batchId={dialogBatch}
      />
    </Paper>
  );
}

function NothingHere() {
  React.useEffect(() => {
    lottie.loadAnimation({
      container: document.getElementById("nothing")!, // the dom element that will contain the animation
      renderer: "svg",
      autoplay: true,
      loop: true,
      path: "nothing.json", // the path to the animation json
    });
  }, []);
  return (
    <Box
      sx={{
        flex: 1,
        alignSelf: "center",
        justifyContent: "center",
      }}
    >
      <Box id="nothing"></Box>
      <Typography align="center" variant="h5">
        There are no batches matching your filters
      </Typography>
    </Box>
  );
}
