import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "../../utils/api";
import { AppThunk } from "../../store";
import { ISliceStatus } from "../../interfaces";
import { IRole } from "../../utils/roles";
import snack from "../../utils/snack";

export interface IUser {
  email: string;
  phone: string;
  role: IRole;
  name: string;
  themes?: string[];
  _id: string;
}

interface IState {
  usersById: { [key: string]: IUser };
  usersIds: string[];
  status: ISliceStatus;
}

const initialState: IState = {
  status: "idle",
  usersById: {},
  usersIds: [],
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    statusChanged: (state, action: PayloadAction<ISliceStatus>) => {
      state.status = action.payload;
    },
    fetched: (state, action: PayloadAction<any[]>) => {
      state.usersById = action.payload.reduce((acc, user) => {
        acc[user.email] = user;
        return acc;
      }, {});
      state.usersIds = action.payload.map((user) => user.email);
      state.status = "ready";
    },
    updated: (state, action: PayloadAction<IUser>) => {
      state.usersById[action.payload.email] = action.payload;
    },
  },
  extraReducers: {
    "session/logOut": () => initialState,
  },
});

export const { statusChanged, fetched, updated } = usersSlice.actions;

export const getUsers = (): AppThunk => async (dispatch) => {
  dispatch(statusChanged("loading"));
  const res = await api.fetch({
    path: "/users",
    method: "GET",
  });
  if (res.ok) {
    dispatch(fetched(res.payload));
  }
};

export const addUser =
  (email: string): AppThunk =>
  async (dispatch) => {
    dispatch(statusChanged("loading"));
    const res = await api.fetch({
      path: "/user",
      method: "POST",
      body: { email },
    });
    if (res.ok) {
      snack.success("User added!");
      dispatch(getUsers());
    }
  };

export const deleteUsers =
  (emails: string[]): AppThunk =>
  async (dispatch) => {
    dispatch(statusChanged("loading"));
    const res = await api.fetch({
      path: "/users",
      method: "DELETE",
      body: { emails },
    });
    if (res.ok) {
      snack.success("Users deleted!");
      dispatch(getUsers());
    }
  };

export const updateUser =
  (user: IUser): AppThunk =>
  async (dispatch) => {
    dispatch(statusChanged("loading"));
    console.log("user", user);
    const res = await api.fetch({
      path: "/user",
      method: "PATCH",
      body: user,
    });
    if (res.ok) {
      snack.success("User updated!");
      dispatch(updated(res.payload));
    }
  };

export const resetUserPassword =
  (email: string): AppThunk =>
  async (dispatch) => {
    dispatch(statusChanged("loading"));
    const res = await api.fetch({
      path: "/userPassword",
      method: "POST",
      body: { email },
    });
    if (res.ok) {
      snack.success("Password reset!");
    }
  };

export default usersSlice.reducer;
