import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Scroller from "../scroller/Scroller";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import SpacingBox from "../SpacingBox";
import { getFlagsScores, saveFlagsScores } from "./flagsSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";

export default function FlagsScoresForm() {
  const { register, errors, handleSubmit } = useForm();
  const theme = useTheme();
  const [isChanged, setChanged] = useState(false);
  const flagsScores = useSelector((state: RootState) =>
    Object.keys(state.flagsSlice.flagsScores).map((flagName) => ({
      flagName,
      score: state.flagsSlice.flagsScores[flagName].score,
      max: state.flagsSlice.flagsScores[flagName].max,
      repeating_words: state.flagsSlice.flagsScores[flagName].repeating_words,
    }))
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getFlagsScores());
  }, [dispatch]);
  return (
    <Paper
      sx={{
        width: 600,
        minWidth: 600,
      }}
    >
      <Box
        sx={{
          padding: theme.spacing(2),
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5">Flags scores</Typography>
        <Button
          disabled={!isChanged}
          color="primary"
          variant="outlined"
          size="small"
          onClick={handleSubmit((formData) => {
            dispatch(saveFlagsScores(formData));
            setChanged(false);
          })}
        >
          Save
        </Button>
      </Box>
      <Divider />
      <Scroller>
        <Box
          sx={{
            "&::-webkitScrollbar": {
              display: "none",
            },
            MsOverflowStyle: "none",
            scrollbarWidth: "none",
            overflowY: "auto",
            height: "100%",
            position: "relative",
          }}
        >
          <List>
            {flagsScores.map((item) => (
              <ListItem>
                <TextField
                  onChange={() => !isChanged && setChanged(true)}
                  sx={{ width: 350 }}
                  error={!!errors[item.flagName]?.score}
                  helperText={errors[item.flagName]?.score?.message}
                  defaultValue={item.score}
                  type="number"
                  inputRef={register({
                    valueAsNumber: true,
                    required: true,
                    validate: (val) =>
                      Number.isInteger(Number(val)) && val >= 0 && val < 10000
                        ? true
                        : "Must be an integer between 0 and 9999",
                  })}
                  name={`${item.flagName}.score`}
                  label={item.flagName}
                  size="small"
                  variant="outlined"
                />
                <SpacingBox />
                <TextField
                  sx={{
                    width: 150,
                  }}
                  onChange={() => !isChanged && setChanged(true)}
                  error={!!errors[item.flagName]?.repeating_words}
                  helperText={errors[item.flagName]?.repeating_words?.message}
                  defaultValue={item.repeating_words || 0}
                  type="number"
                  inputRef={register({
                    valueAsNumber: true,
                    required: true,
                    validate: (val) =>
                      Number.isInteger(Number(val)) && val >= 0 && val <= 100
                        ? true
                        : "Must be an integer between 0 and 100",
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  name={`${item.flagName}.repeating_words`}
                  label={"Repeating words"}
                  size="small"
                  variant="outlined"
                />
                <SpacingBox />
                <TextField
                  sx={{
                    width: 150,
                  }}
                  type="number"
                  onChange={() => !isChanged && setChanged(true)}
                  error={!!errors[item.flagName]?.max}
                  helperText={errors[item.flagName]?.max?.message}
                  defaultValue={item.max}
                  inputRef={register({
                    valueAsNumber: true,
                    required: true,
                    validate: (val) =>
                      Number.isInteger(Number(val)) && val >= 0 && val < 10000
                        ? true
                        : "Must be an integer between 0 and 9999",
                  })}
                  name={`${item.flagName}.max`}
                  label={"Max"}
                  placeholder="0 for no max"
                  size="small"
                  variant="outlined"
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Scroller>
    </Paper>
  );
}
