import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "../../utils/api";
import { ISliceStatus } from "../../interfaces";
import { AppThunk } from "../../store";

export type IUpdateStatus =
  | "Preparing"
  | "Running GBQ"
  | "Failed"
  | "Indexing database"
  | "Transferring results"
  | "Completed";
interface IUpdate {
  updatedAt: number;
  attemptedAt: number;
  status: IUpdateStatus;
  message?: string;
}
interface IState {
  update: IUpdate;
  status: ISliceStatus;
}

const initialState: IState = {
  update: {
    updatedAt: Date.now(),
    attemptedAt: Date.now(),
    status: "Completed",
  },
  status: "idle",
};

export const dataManagerSlice = createSlice({
  name: "dataManager",
  initialState,
  reducers: {
    statusChanged: (state, action: PayloadAction<ISliceStatus>) => {
      state.status = action.payload;
    },
    updateFetched: (state, action: PayloadAction<IUpdate>) => {
      state.status = "ready";
      state.update = action.payload;
    },
  },
  extraReducers: {
    "session/logOut": () => initialState,
  },
});

export const { statusChanged, updateFetched } = dataManagerSlice.actions;

export const getUpdate =
  (isRefreshing?: boolean): AppThunk =>
  async (dispatch) => {
    !isRefreshing && dispatch(statusChanged("loading"));
    const res = await api.fetch({
      path: "/update",
      method: "GET",
      silent: true,
    });
    if (res.ok) {
      dispatch(updateFetched(res.payload));
    }
  };

export const postUpdate = (): AppThunk => async (dispatch) => {
  dispatch(statusChanged("loading"));
  const res = await api.fetch({
    path: "/update",
    method: "POST",
  });
  if (res.ok) {
    dispatch(updateFetched(res.payload));
  }
};

export default dataManagerSlice.reducer;
