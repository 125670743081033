import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { Outlet } from "react-router-dom";
import LoggedInWrapper from "./LoggedInWrapper";

export default function MiniDrawer() {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "100vh",
        backgroundColor: theme.palette.grey["100"],
        width: "100%",
      }}
    >
      <CssBaseline />
      <LoggedInWrapper>
        <Outlet />
      </LoggedInWrapper>
    </Box>
  );
}
