import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../../store";
import { ISliceStatus } from "../../interfaces";
import api from "../../utils/api";
import snack from "../../utils/snack";

interface IThresholds {
  [pipelineId: string]: number;
}
interface IFlagsScores {
  [flagName: string]: {
    score: number;
    max: number;
    repeating_words: number;
  };
}
interface IState {
  thresholds: IThresholds;
  flagsScores: IFlagsScores;
  badWordsList: string[];
  badWordsByFlagName: {
    [flagName: string]: string;
  };
  status: ISliceStatus;
}

const initialState: IState = {
  thresholds: {},
  flagsScores: {},
  badWordsList: [],
  badWordsByFlagName: {},
  status: "idle",
};

export const flagsSlice = createSlice({
  name: "flags",
  initialState,
  reducers: {
    statusChanged: (state, action: PayloadAction<ISliceStatus>) => {
      state.status = action.payload;
    },
    thresholdsFetched: (state, action: PayloadAction<string>) => {
      state.status = "ready";
      action.payload.split("\n").map((row) => {
        const [pipelineId, score] = row.split(",");
        state.thresholds[pipelineId] = Number(score);
      });
    },
    flagsScoresFetched: (state, action: PayloadAction<string>) => {
      state.status = "ready";
      action.payload.split("\n").map((row) => {
        const [flagName, score, max, repeating_words] = row.split(",");
        state.flagsScores[flagName] = {
          score: Number(score),
          max: Number(max),
          repeating_words: Number(repeating_words),
        };
      });
    },
    badWordsListFetched: (state, action: PayloadAction<string[]>) => {
      state.status = "ready";
      state.badWordsList = action.payload.map((item) =>
        item.replace(/^bad_words\/|\.csv$/g, "")
      );
    },
    badWordsFetched: (
      state,
      action: PayloadAction<{ flagName: string; badWords: string }>
    ) => {
      state.status = "ready";
      state.badWordsByFlagName[action.payload.flagName] =
        action.payload.badWords;
    },
  },
  extraReducers: {
    "session/logOut": () => initialState,
  },
});

export const {
  statusChanged,
  thresholdsFetched,
  flagsScoresFetched,
  badWordsListFetched,
  badWordsFetched,
} = flagsSlice.actions;

export const getThresholds = (): AppThunk => async (dispatch) => {
  dispatch(statusChanged("loading"));
  const res = await api.fetch({
    path: `/gcsData/thresholds.csv`,
    method: "GET",
  });
  if (res.ok) {
    dispatch(thresholdsFetched(res.payload));
  }
};
export const saveThresholds =
  (thresholds: IThresholds): AppThunk =>
  async (dispatch) => {
    dispatch(statusChanged("loading"));
    const csvString = Object.keys(thresholds)
      .map((pipelineId) => {
        return `${pipelineId},${thresholds[pipelineId]}`;
      })
      .join("\n");
    const res = await api.fetch({
      path: `/gcsData/thresholds.csv`,
      method: "POST",
      body: { dataString: csvString },
    });
    if (res.ok) {
      snack.success("Saved 🚀");
      dispatch(thresholdsFetched(csvString));
    }
  };

export const getFlagsScores = (): AppThunk => async (dispatch) => {
  dispatch(statusChanged("loading"));
  const res = await api.fetch({
    path: `/gcsData/flags_scores.csv`,
    method: "GET",
  });
  if (res.ok) {
    dispatch(flagsScoresFetched(res.payload));
  }
};
export const saveFlagsScores =
  (flagsScores: IFlagsScores): AppThunk =>
  async (dispatch) => {
    const csvString = Object.keys(flagsScores)
      .map((flagName) => {
        return `${flagName},${flagsScores[flagName].score},${flagsScores[flagName].max},${flagsScores[flagName].repeating_words}`;
      })
      .join("\n");
    const res = await api.fetch({
      path: `/gcsData/flags_scores.csv`,
      method: "POST",
      body: { dataString: csvString },
    });
    if (res.ok) {
      snack.success("Saved 🚀");
      dispatch(flagsScoresFetched(csvString));
    }
  };

export const getBadWordsList = (): AppThunk => async (dispatch) => {
  dispatch(statusChanged("loading"));
  const res = await api.fetch({
    path: `/gcsObjectsList/bad_words`,
    method: "GET",
  });
  if (res.ok) {
    dispatch(badWordsListFetched(res.payload));
  }
};

export const getBadWords =
  (flagName: string): AppThunk =>
  async (dispatch) => {
    dispatch(statusChanged("loading"));
    const res = await api.fetch({
      path: `/gcsData/bad_words/${flagName}.csv`,
      method: "GET",
    });
    if (res.ok) {
      dispatch(badWordsFetched({ flagName, badWords: res.payload }));
    }
  };

export const saveBadWords =
  (flagName: string, badWords: string): AppThunk =>
  async (dispatch) => {
    dispatch(statusChanged("loading"));

    const res = await api.fetch({
      path: `/gcsData/bad_words/${flagName}.csv`,
      method: "POST",
      body: { dataString: badWords },
    });
    if (res.ok) {
      snack.success("Saved 🚀");
      dispatch(badWordsFetched({ flagName, badWords }));
    }
  };

export interface IFlagsDict {
  [flagId: string]: string[];
}

export const bulkRemoveBadWords =
  (flagsDict: IFlagsDict): AppThunk =>
  async (dispatch) => {
    dispatch(statusChanged("loading"));
    const flags = Object.keys(flagsDict);
    for await (const flagName of flags) {
      const res = await api.fetch({
        path: `/gcsData/bad_words/${flagName}.csv`,
        method: "GET",
        silent: true,
      });
      if (!res.payload) {
        continue;
      }
      let count = 0;
      const out = res.payload
        .split("\n")
        .filter((word: string) => {
          if (flagsDict[flagName].includes(word)) {
            count++;
            return false;
          }
          return true;
        })
        .join("\n");

      const saveRes =
        count > 0
          ? await api.fetch({
              path: `/gcsData/bad_words/${flagName}.csv`,
              method: "POST",
              body: { dataString: out },
            })
          : { ok: true };
      if (saveRes.ok) {
        snack.info(
          `✅ ${flagName}: ${count} out of ${flagsDict[flagName].length}`
        );
        dispatch(badWordsFetched({ flagName, badWords: out }));
      } else {
        snack.info(`Error updating ${flagName}`);
      }
    }
    dispatch(statusChanged("ready"));
    snack.success(`Done!`);
  };

export default flagsSlice.reducer;
