import { LoadingButton } from "@mui/lab";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useDispatch, useSelector } from "react-redux";
import { getGbqTables } from "./tableSelectSlice";
import { RootState } from "../../store";
import { Tooltip } from "@mui/material";

export default function TableSelectRefresh() {
  const dispatch = useDispatch();
  const isLoading = useSelector(
    (state: RootState) => state.tableSelect.status === "loading"
  );

  return (
    <Tooltip title="Refresh tables list">
      <span>
        <LoadingButton
          loading={isLoading}
          onClick={() => {
            dispatch(getGbqTables());
          }}
        >
          <RefreshIcon />
        </LoadingButton>
      </span>
    </Tooltip>
  );
}
