import Paper from "@mui/material/Paper";
import Scroller from "../scroller/Scroller";
import {
  DataGridPremium,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridActionsCellItem,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowsProp,
  GridToolbarContainer,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import LinearProgress from "@mui/material/LinearProgress";
import { IUser, getUsers, updateUser } from "./usersSlice";
import { allRoles } from "../../utils/roles";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import { UsersTableToolbar } from "./UsersTableToolbar";

export default function UsersView() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUsers());
  }, []);
  const handleRefresh = () => {
    dispatch(getUsers());
  };
  const isLoading = useSelector(
    (state: RootState) => state.models.status === "loading"
  );

  const rows = useSelector((state: RootState) =>
    state.users.usersIds.map((userId) => state.users.usersById[userId])
  );

  const apiRef = useGridApiRef();

  const handleEditUser = (udpatedUser: IUser) => {
    dispatch(updateUser(udpatedUser));
    return udpatedUser;
  };
  const getSelectedRows = () =>
    Array.from(apiRef.current?.getSelectedRows())
      .map((item) => item[1]?.email)
      .filter(Boolean) || [];

  return (
    <Paper>
      <Scroller>
        <DataGridPremium
          rows={rows}
          apiRef={apiRef}
          hideFooter
          disableRowSelectionOnClick
          checkboxSelection
          getRowId={(row) => row._id}
          slots={{
            loadingOverlay: LinearProgress,
            toolbar: () => (
              <UsersTableToolbar
                getSelectedRows={getSelectedRows}
                handleRefresh={handleRefresh}
              />
            ),
          }}
          loading={isLoading}
          processRowUpdate={handleEditUser}
          disableColumnFilter
          disableAggregation
          pinnedColumns={{
            left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, "email"],
          }}
          editMode="row"
          columns={[
            { field: "email", headerName: "Email", width: 200, editable: true },
            { field: "phone", headerName: "Phone", width: 200, editable: true },
            // select cell. Options: allRoles
            {
              field: "role",
              headerName: "Role",
              width: 200,
              type: "singleSelect",
              valueOptions: allRoles,
              editable: true,
              description: `admin - full access; explorer - access only to explorer, swiper, model garden and tables manager.`,
            },
            { field: "name", headerName: "Name", width: 200, editable: true },
            {
              field: "themes",
              headerName: "Themes",
              width: 600,
              valueFormatter: (params) => params.value?.join(", "),
              valueParser: (newValue) =>
                newValue
                  .split(",")
                  .map((v: string) => v.trim())
                  .filter(Boolean),
              editable: true,
              description:
                'Further limits access for users with "explorer" role. If not empty, then the user can see only resources associated with these themes. Comma-separated list of themes. Beware of typos!',
            },
          ]}
        />
      </Scroller>
    </Paper>
  );
}

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel
  ) => void;
}
