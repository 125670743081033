import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "../../utils/api";
import { AppThunk } from "../../store";
import { ISliceStatus } from "../../interfaces";
import { selectFile } from "../../utils/selectFile";
import snack from "../../utils/snack";

export interface IDatastoreMessage {
  id: string;
  recipient_phone_number: string;
  agent_phone_number: string;
  voicemail_url?: string;
  voicemail_text_followup?: string;
  metadata: { [key: string]: string };
  answered_text_followup?: string;
  not_answered_text_followup?: string;
  created_at: number;
  answered_by: string;
  voicemail_sent?: boolean;
  answered_text_followup_sent?: boolean;
  not_answered_text_followup_sent?: boolean;
  voicemail_text_followup_sent?: boolean;
  recipient_called_back_at?: number;
  recipient_texted_back_at?: number;
  previous_records?: IDatastoreMessage[];
  error?: string;
}
interface IState {
  messages: IDatastoreMessage[];
  status: ISliceStatus;
}

const initialState: IState = {
  messages: [],
  status: "idle",
};

export const smsOutreachSlice = createSlice({
  name: "smsOutreach",
  initialState,
  reducers: {
    statusChanged: (state, action: PayloadAction<ISliceStatus>) => {
      state.status = action.payload;
    },
    fetched: (state, action: PayloadAction<IDatastoreMessage[]>) => {
      state.status = "ready";
      const messages: IDatastoreMessage[] = [];
      for (const message of action.payload) {
        messages.push({ ...message, id: message.recipient_phone_number });
        if (message.previous_records) {
          // with index
          for (const [index, previous] of message.previous_records.entries()) {
            messages.push({
              ...previous,
              id: `${message.recipient_phone_number}-${index}`,
            });
          }
        }
      }
      state.messages = messages;
    },
  },
  extraReducers: {
    "session/logOut": () => initialState,
  },
});

export const { statusChanged, fetched } = smsOutreachSlice.actions;

export const getOutreachMessages = (): AppThunk => async (dispatch) => {
  dispatch(statusChanged("loading"));
  const res = await api.fetch({
    path: "/outreachMessages",
    method: "GET",
  });
  if (res.ok) {
    dispatch(fetched(res.payload));
  }
};

export const postOutreachMessages = (): AppThunk => async (dispatch) => {
  dispatch(statusChanged("loading"));
  const csvString = await selectFile();
  const res = await api.fetch({
    path: "/outreachMessages",
    method: "POST",
    body: { csvString },
  });
  if (res.ok) {
    snack.success("Successfully submitted!");
    dispatch(getOutreachMessages());
  }
};

export default smsOutreachSlice.reducer;
