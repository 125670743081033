import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../../store";
import api from "../../utils/api";
import { ISliceStatus } from "../../interfaces";
import { batch } from "react-redux";
import { getTablePreview } from "../table/tableSlice";

interface IState {
  status: ISliceStatus;
}
const initialState: IState = {
  status: "idle",
};

export const countWordsSlice = createSlice({
  name: "countWords",
  initialState,
  reducers: {
    statusChanged: (state, action: PayloadAction<ISliceStatus>) => {
      state.status = action.payload;
    },
  },
});

export const { statusChanged } = countWordsSlice.actions;

interface ICountWords {
  words: string[];
  theme?: string;
}
export const countWords =
  (tableId: string, body: ICountWords): AppThunk =>
  async (dispatch) => {
    if (!body.theme) {
      if (
        !window.confirm(
          "You didn't select a theme. Are you sure you want to continue?"
        )
      ) {
        return;
      }
    }
    dispatch(statusChanged("loading"));
    const res = await api.fetch({
      path: `/gbqCountWords/${tableId}`,
      method: "POST",
      body: body,
    });
    if (res.ok) {
      batch(() => {
        dispatch(getTablePreview(res.payload.destinationTableId));
        dispatch(statusChanged("ready"));
      });
    } else {
      dispatch(statusChanged("error"));
    }
  };

export default countWordsSlice.reducer;
