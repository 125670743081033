import { IRole } from "../utils/roles";
import MemoryIcon from "@mui/icons-material/Memory";
import SettingsIcon from "@mui/icons-material/Settings";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ExploreIcon from "@mui/icons-material/Explore";
import EditAttributesIcon from "@mui/icons-material/EditAttributes";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { checkToken, logOut } from "./sessionSlice";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import { useTheme } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as Logo } from "../logo.svg";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Link } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import { RootState } from "../store";
import { useDispatch, useSelector } from "react-redux";
import qs from "qs";
import Avatar from "../features/Avatar";
import { useEffect } from "react";
import MessageIcon from "@mui/icons-material/Message";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import DeblurIcon from "@mui/icons-material/Deblur";
import useQuery from "../hooks/useQuery";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import PhoneIcon from "@mui/icons-material/Phone";
import Typography from "@mui/material/Typography";
import SpacingBox from "../features/SpacingBox";
import PeopleIcon from "@mui/icons-material/People";

interface IMenuItem {
  title: string;
  icon: React.FunctionComponent;
  href?: string;
  action?: Function;
  access: IRole[];
  makeQuery?: (currentQuery: any) => string | undefined;
}
const items: IMenuItem[] = [
  {
    title: "Batch Manager",
    icon: MemoryIcon,
    href: "/datamanager",
    access: ["admin"],
  },
  {
    title: "Flags settings",
    icon: SettingsIcon,
    href: "/flagssettings",
    access: ["admin"],
  },
  {
    title: "Pipeline Status",
    icon: AccountTreeIcon,
    href: "/pipeline",
    access: ["admin"],
  },
  {
    title: "Tables manager",
    icon: AppRegistrationIcon,
    href: "/tablemanager",
    access: [],
  },
  {
    title: "Model garden",
    icon: ModelTrainingIcon,
    href: "/models",
    access: [],
  },
  {
    title: "Explorer",
    icon: ExploreIcon,
    makeQuery: (currentQuery: any) =>
      currentQuery?.theme || currentQuery?.tableId
        ? qs.stringify({
            theme: currentQuery.theme,
            tableId: currentQuery.tableId,
          })
        : undefined,
    href: "/explorer",
    access: [],
  },
  {
    title: "Swiper",
    icon: DeblurIcon,
    href: "/swiper",
    makeQuery: (currentQuery: any) =>
      currentQuery?.theme || currentQuery?.tableId
        ? qs.stringify({
            theme: currentQuery.theme,
            tableId: currentQuery.tableId,
          })
        : undefined,
    access: [],
  },
  {
    title: "Attributes",
    icon: EditAttributesIcon,
    href: "/attributes",
    access: ["admin"],
  },
  {
    title: "Newspapers",
    icon: NewspaperIcon,
    href: "/newspapers",
    access: ["admin"],
  },
  {
    title: "Outreach",
    icon: MessageIcon,
    href: "/outreach",
    access: ["admin"],
  },
  {
    title: "Phone masking",
    icon: PhoneIcon,
    href: "/phoneMasking",
    access: ["admin"],
  },
  {
    title: "Users",
    icon: PeopleIcon,
    href: "/users",
    access: ["admin"],
  },
  {
    title: "Log out",
    icon: ExitToAppIcon,
    action: logOut,
    access: [],
  },
];

interface IProps {
  closeDrawer: () => void;
  hideTooltips?: boolean;
  closeOnClick?: boolean;
  isOpen?: boolean;
}
export default function DrawerContent(props: IProps) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { query } = useQuery();

  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(checkToken());
  }, [dispatch]);

  const session = useSelector((state: RootState) => state.session);
  const menuItems = useSelector((state: RootState) =>
    items.filter(
      (item) => !item.access.length || item.access.includes(state.session.role!)
    )
  );
  const name = session.name || session.email;
  return (
    <>
      <List
        dense
        disablePadding
        sx={{
          display: "flex",
          alignItems: "center",
          ...theme.mixins.toolbar,
          "& li": {
            display: "flex",
            flex: 1,
          },
        }}
      >
        <ListItem>
          <Avatar name={name} />
          <ListItemText primary={name} secondary={session.role} />
          <ListItemSecondaryAction>
            <IconButton edge="end" onClick={props.closeDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
      <Divider />
      <List>
        {menuItems.map((item) => (
          <Tooltip
            key={item.title}
            title={item.title}
            disableFocusListener={props.hideTooltips}
            disableHoverListener={props.hideTooltips}
            disableTouchListener={props.hideTooltips}
            placement="right"
          >
            <ListItem
              {...(item.href
                ? {
                    component: Link,
                    to: {
                      pathname: item.href,
                      search:
                        item.makeQuery && query
                          ? item.makeQuery(query)
                          : undefined,
                    },
                  }
                : {})}
              onClick={item.action ? () => dispatch(item.action!()) : undefined}
              key={item.title}
              disablePadding
              sx={{ display: "block" }}
            >
              <ListItemButton
                selected={pathname === item.href}
                sx={{
                  minHeight: 48,
                  justifyContent: props.isOpen ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: props.isOpen ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <item.icon />
                </ListItemIcon>
                <ListItemText
                  primary={item.title}
                  sx={{ opacity: props.isOpen ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </Tooltip>
        ))}
      </List>
      <Box flex={1} />
      <Divider />
      {/* <Box sx={props.isOpen ? undefined : { display: "none" }}>
        <UpdatesView />
      </Box>
      <Divider /> */}
      <Box
        sx={{
          padding: theme.spacing(2),
          flexDirection: "column",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Logo />
        <SpacingBox />
        <Typography variant="caption" color="textSecondary">
          1.3.12
        </Typography>
      </Box>
    </>
  );
}
