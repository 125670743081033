import Box from "@mui/material/Box";
import BadWordsForm from "./BadWordsForm";
import SpacingBox from "../SpacingBox";
import PipelinesList from "./Thresholds";
import FlagsScoresForm from "./FlagsScoresForm";
import { HorizontalScroller } from "../scroller/Scroller";

export default function FlagsSettingsView() {
  return (
    <HorizontalScroller>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <PipelinesList />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <SpacingBox />
        <FlagsScoresForm />
        <SpacingBox />
        <BadWordsForm />
      </Box>
    </HorizontalScroller>
  );
}
