import Joi from "joi";

export interface ISwiperQuery {
  tableId?: string;
  theme?: string;
  rowIndex: string;
}

export const swiperQuerySchema = Joi.object({
  tableId: Joi.string().regex(/^peg\-sandbox:explorer\.[^\.]+$/),
  theme: Joi.string().regex(/^[a-z0-9_]{5,200}$/),
  rowIndex: Joi.number(),
});
