import { useSelector } from "react-redux";
import { RootState } from "../../store";
import useQuery from "../../hooks/useQuery";
import { IExplorerQuery, explorerQuerySchema } from "../explorer/ExplorerQuery";
import Button from "@mui/material/Button";
import { useState } from "react";
import Popper from "@mui/material/Popper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";

export function LostLabels() {
  const { query } = useQuery<IExplorerQuery>(explorerQuerySchema);
  const { tableId } = query;
  const lost_labels = useSelector((state: RootState) =>
    tableId ? state.tableSelect.tablesById[tableId]?.lost_labels : undefined
  );
  const tableTheme = useSelector((state: RootState) =>
    tableId ? state.tableSelect.tablesById[tableId]?.theme : undefined
  );
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopperOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopperClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  if (!lost_labels || !tableTheme) {
    return null;
  }
  return (
    <Box onMouseEnter={handlePopperOpen} onMouseLeave={handlePopperClose}>
      <Button>Lost labels: {lost_labels?.length}</Button>
      <Popper sx={{ zIndex: 3, width: 350 }} open={open} anchorEl={anchorEl}>
        <Paper elevation={8}>
          <Box
            sx={{
              p: 2,
            }}
          >
            <Tooltip
              title={`Below is the list of companies that were marked as good in ${tableTheme} theme yet not present in this table.`}
            >
              <Typography variant="h5">Lost "good" labels</Typography>
            </Tooltip>
          </Box>
          <Divider />
          <Box
            sx={{
              p: 2,
            }}
          >
            <TextField
              name="words"
              size="small"
              multiline
              minRows={3}
              maxRows={10}
              value={lost_labels?.join("\n")}
              fullWidth
              contentEditable={false}
            />
          </Box>
        </Paper>
      </Popper>
    </Box>
  );
}
