import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Scroller from "../scroller/Scroller";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getThresholds, saveThresholds } from "./flagsSlice";
import { RootState } from "../../store";
import Button from "@mui/material/Button";

export default function PipelinesList() {
  const { register, errors, handleSubmit } = useForm();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [isChanged, setChanged] = useState(false);
  const thresholds = useSelector((state: RootState) =>
    Object.keys(state.flagsSlice.thresholds).map((pipelineId) => ({
      pipelineId,
      score: state.flagsSlice.thresholds[pipelineId],
    }))
  );
  useEffect(() => {
    dispatch(getThresholds());
  }, [dispatch]);
  return (
    <Paper
      sx={{
        width: 290,
        minWidth: 290,
      }}
    >
      <Box
        sx={{
          padding: theme.spacing(2),
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5">Thresholds</Typography>
        <Button
          disabled={!isChanged}
          color="primary"
          variant="outlined"
          size="small"
          onClick={handleSubmit((formData) => {
            dispatch(saveThresholds(formData));
            setChanged(false);
          })}
        >
          Save
        </Button>
      </Box>
      <Divider />
      <Scroller>
        <Box
          sx={{
            "&::-webkitScrollbar": {
              display: "none",
            },
            MsOverflowStyle: "none",
            scrollbarWidth: "none",
            overflowY: "auto",
            height: "100%",
            position: "relative",
          }}
        >
          <List>
            {thresholds.map((item) => (
              <ListItem>
                <TextField
                  fullWidth
                  error={!!errors[item.pipelineId]}
                  helperText={errors[item.pipelineId]?.message}
                  onChange={() => !isChanged && setChanged(true)}
                  defaultValue={item.score}
                  inputRef={register({
                    valueAsNumber: true,
                    required: true,
                    validate: (val) =>
                      Number.isInteger(Number(val)) && val >= 0 && val < 10000
                        ? true
                        : "Must be an integer between 0 and 9999",
                  })}
                  name={item.pipelineId}
                  label={item.pipelineId}
                  size="small"
                  variant="outlined"
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Scroller>
    </Paper>
  );
}
