import { useTheme } from "@mui/material/styles";
import { ReactComponent as Logo } from "../../logo.svg";
import React from "react";
import { logInWithPassword, mfa } from "../../wrapper/sessionSlice";
import { useForm } from "react-hook-form";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import PhoneIcon from "@mui/icons-material/Phone";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import SpacingBox from "../SpacingBox";

export default function Login() {
  const theme = useTheme();
  const [showPassword, setShowPassword] = React.useState(false);
  const { register, handleSubmit, errors, setValue } = useForm();
  const dispatch = useDispatch();
  const [otpSent, setOtpSent] = React.useState(false);

  const submit = handleSubmit(({ phone, otp, password }) => {
    if (otpSent) {
      dispatch(logInWithPassword(phone, otp));
    } else {
      dispatch(mfa(phone, password));
      setValue("password", "");
      setOtpSent(true);
    }
  });
  const isLoading = useSelector(
    (state: RootState) => state.session.status === "loading"
  );

  return (
    <Box height="100vh" alignItems="center" display="flex" flex={1}>
      <Container
        maxWidth="xs"
        sx={{
          padding: 0,
          [theme.breakpoints.down("xs")]: {
            maxWidth: "100%",
          },
        }}
      >
        <Paper
          elevation={20}
          sx={{
            display: "flex",
            flex: 1,
            // backgroundColor: theme.palette.grey['200'],
            [theme.breakpoints.down("xs")]: {
              height: "100vh",
            },
          }}
        >
          <Box padding={2} display="flex" flexDirection="column" flex={1}>
            <Box
              sx={{
                padding: theme.spacing(4),
              }}
            >
              <Logo />
            </Box>
            <Box
              flex={1}
              alignItems="stretch"
              display="flex"
              flexDirection="column"
            >
              <FormControl
                sx={{
                  marginBottom: theme.spacing(2),
                }}
                variant="outlined"
                fullWidth
              >
                <InputLabel htmlFor="phoneInput">Phone</InputLabel>
                <OutlinedInput
                  label="Phone"
                  name="phone"
                  placeholder="234 567 8901"
                  inputRef={register({
                    required: { value: true, message: "Phone is required" },
                    pattern: {
                      value: /^\d{10,13}$/,
                      message: "the digits, nothing else",
                    },
                  })}
                  error={!!errors.phone}
                  id="phoneInput"
                  type="phone"
                  endAdornment={
                    <InputAdornment position="end">
                      <PhoneIcon />
                    </InputAdornment>
                  }
                />
                <FormHelperText>
                  {errors.phone?.message || "10 to 13 digits"}
                </FormHelperText>
              </FormControl>
              <FormControl
                variant="outlined"
                fullWidth
                sx={{
                  marginBottom: theme.spacing(2),
                }}
              >
                {otpSent ? (
                  <>
                    <InputLabel htmlFor="otp">SMS password</InputLabel>
                    <OutlinedInput
                      label="SMS password"
                      name="otp"
                      defaultValue={""}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "Password is required",
                        },
                      })}
                      error={!!errors.otp}
                      onKeyPress={(key) => key.charCode === 13 && submit()}
                      id="otp"
                      type={"text"}
                    />
                    <FormHelperText>
                      {errors.otp?.message || "6 characters or more"}
                    </FormHelperText>
                  </>
                ) : (
                  <>
                    <InputLabel htmlFor="passwordInput">Password</InputLabel>
                    <OutlinedInput
                      label="Password"
                      name="password"
                      inputRef={register({
                        required: {
                          value: true,
                          message: "Password is required",
                        },
                        minLength: {
                          value: 6,
                          message: "Password must be longer than 6 characters",
                        },
                        maxLength: {
                          value: 32,
                          message:
                            "Password must be shorter than 32 characters",
                        },
                      })}
                      error={!!errors.password}
                      onKeyPress={(key) => key.charCode === 13 && submit()}
                      id="passwordInput"
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <FormHelperText>
                      {errors.password?.message || "6 characters or more"}
                    </FormHelperText>
                  </>
                )}
              </FormControl>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {otpSent && (
                  <Button onClick={() => setOtpSent(false)} variant="outlined">
                    BACK
                  </Button>
                )}
                <SpacingBox />
                <Button
                  disabled={!!errors.length}
                  onClick={submit}
                  variant="outlined"
                  endIcon={
                    isLoading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <AccountCircleIcon />
                    )
                  }
                >
                  LOG IN
                </Button>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
}
