import Paper from "@mui/material/Paper";
import Scroller from "../scroller/Scroller";
import {
  DataGridPremium,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import LinearProgress from "@mui/material/LinearProgress";
import moment from "moment";
import TextCell from "../textCell/TextCell";
import { getModels } from "./modelsSlice";
import ColorCodedCell from "../colorCodedCell/ColorCodedCell";

export default function ModelGardenView() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getModels());
  }, []);
  const isLoading = useSelector(
    (state: RootState) => state.models.status === "loading"
  );

  const rows = useSelector((state: RootState) =>
    state.models.models.map((m) => ({
      ...m,
      "created_by.name": m.created_by?.name,
    }))
  );

  console.log(rows);

  return (
    <Paper>
      <Scroller>
        <DataGridPremium
          rows={rows}
          // hideFooter
          disableRowSelectionOnClick
          checkboxSelection
          getRowId={(row) => row.id || row._id}
          slots={{
            // toolbar: () => (
            //   <TablesManagerToolbar getSelectedRows={getSelectedRows} />
            // ),
            loadingOverlay: LinearProgress,
          }}
          loading={isLoading}
          unstable_headerFilters
          // density="compact"
          pinnedColumns={{
            left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, "id"],
          }}
          columns={[
            {
              field: "id",
              headerName: "id",
              valueFormatter: ({ value }) => value?.split(".").pop(),
              width: 300,
            },
            {
              field: "theme",
              headerName: "theme",
              width: 200,
            },
            {
              field: "precision",
              headerName: "precision",
              width: 120,
              filterable: false,
              valueFormatter: ({ value }) => value?.toFixed(4),
              renderCell: (props) => (
                <ColorCodedCell
                  {...props}
                  color={
                    props.value > 0.9999
                      ? "red"
                      : props.value > 0.95
                      ? "green"
                      : props.value > 0.9
                      ? "orange"
                      : "red"
                  }
                />
              ),
            },
            {
              field: "recall",
              headerName: "recall",
              width: 120,
              filterable: false,
              valueFormatter: ({ value }) => value?.toFixed(4),
              renderCell: (props) => (
                <ColorCodedCell
                  {...props}
                  color={
                    props.value > 0.9999
                      ? "red"
                      : props.value > 0.9
                      ? "green"
                      : props.value > 0.85
                      ? "orange"
                      : "red"
                  }
                />
              ),
            },
            {
              field: "accuracy",
              headerName: "accuracy",
              width: 120,
              filterable: false,
              valueFormatter: ({ value }) => value?.toFixed(4),
              renderCell: (props) => (
                <ColorCodedCell
                  {...props}
                  color={
                    props.value > 0.9999
                      ? "red"
                      : props.value > 0.9
                      ? "green"
                      : props.value > 0.85
                      ? "orange"
                      : "red"
                  }
                />
              ),
            },
            {
              field: "f1_score",
              headerName: "f1_score",
              width: 120,
              filterable: false,
              valueFormatter: ({ value }) => value?.toFixed(4),
              renderCell: (props) => (
                <ColorCodedCell
                  {...props}
                  color={
                    props.value > 0.9999
                      ? "red"
                      : props.value > 0.9
                      ? "green"
                      : props.value > 0.85
                      ? "orange"
                      : "red"
                  }
                />
              ),
            },
            {
              field: "log_loss",
              headerName: "log_loss",
              width: 120,
              filterable: false,
              valueFormatter: ({ value }) => value?.toFixed(4),
              renderCell: (props) => (
                <ColorCodedCell
                  {...props}
                  color={
                    props.value < 0.001
                      ? "red"
                      : props.value < 0.015
                      ? "green"
                      : props.value < 0.2
                      ? "orange"
                      : "red"
                  }
                />
              ),
            },
            {
              field: "roc_auc",
              headerName: "roc_auc",
              width: 120,
              filterable: false,
              valueFormatter: ({ value }) => value?.toFixed(4),
              renderCell: (props) => (
                <ColorCodedCell
                  {...props}
                  color={
                    props.value > 0.9999
                      ? "red"
                      : props.value > 0.95
                      ? "green"
                      : props.value > 0.9
                      ? "orange"
                      : "red"
                  }
                />
              ),
            },
            {
              field: "created_by.name",
              headerName: "created_by",
              width: 200,
            },
            {
              field: "created_at",
              headerName: "created_at",
              type: "date",
              width: 200,
              valueFormatter: ({ value }) =>
                value ? moment(value).fromNow() : null,
            },
            {
              field: "modified_at",
              headerName: "modified_at",
              type: "date",
              width: 200,
              valueFormatter: ({ value }) =>
                value ? moment(value).fromNow() : null,
            },
            {
              field: "model_type",
              headerName: "model_type",
              width: 200,
            },
            {
              field: "feature_columns",
              headerName: "feature_columns",
              width: 200,
              valueFormatter: ({ value }) =>
                (value as { name: string }[]).reduce(
                  (acc, item) => acc + item.name + "\n",
                  ""
                ),
              renderCell: (props) => <TextCell {...props} />,
            },
          ]}
        />
      </Scroller>
    </Paper>
  );
}
