import TextField from "@mui/material/TextField";
import debounce from "lodash/debounce";
import { useCallback, useEffect, useState } from "react";

interface IProps extends React.ComponentProps<typeof TextField> {
  onApply: (value: string) => void;
}

export default function DebouncedTextField(props: IProps) {
  const { onApply, value, ...rest } = props;

  const debouncedSetQuery = useCallback(
    debounce((value: string) => {
      onApply(value);
    }, 1000),
    [onApply]
  );

  const [val, setVal] = useState(value);
  useEffect(() => {
    setVal(value);
  }, [value]);
  return (
    <TextField
      value={val}
      onChange={(e) => {
        setVal(e.target.value);
        debouncedSetQuery(e.target.value);
      }}
      {...rest}
    />
  );
}
