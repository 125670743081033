import { useSelector } from "react-redux";
import { RootState } from "../../store";
import Tooltip from "@mui/material/Tooltip";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import TableClusteringForm from "./TableClusteringForm";
import { useState } from "react";

export const TableClustering = () => {
  const isLoading = useSelector(
    (state: RootState) => state.tableClustering.status === "loading"
  );
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopperOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopperClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box>
      <Tooltip title="Cluster the selected table">
        <span>
          <LoadingButton
            disabled={isLoading}
            loading={isLoading}
            onClick={handlePopperOpen}
          >
            Cluster
          </LoadingButton>
        </span>
      </Tooltip>
      <Popper sx={{ zIndex: 3, width: 550 }} open={open} anchorEl={anchorEl}>
        <TableClusteringForm handlePopperClose={handlePopperClose} />
      </Popper>
    </Box>
  );
};
