import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import useQuery from "../../hooks/useQuery";
import Tooltip from "@mui/material/Tooltip";
import LoadingButton from "@mui/lab/LoadingButton";
import DownloadIcon from "@mui/icons-material/Download";
import { IExplorerQuery, explorerQuerySchema } from "../explorer/ExplorerQuery";
import { GridColumnVisibilityModel } from "@mui/x-data-grid-premium";
import { getLocalStorageItem } from "../../hooks/useLocalStorage";
import { downloadTable } from "./tableSlice";

export default function TableDownload() {
  const dispatch = useDispatch();
  const { query } = useQuery<IExplorerQuery>(explorerQuerySchema);
  const { tableId } = query;

  const isLoading = useSelector(
    (state: RootState) => state.tableSelect.status === "loading"
  );

  const selectedTable = useSelector((state: RootState) =>
    tableId ? state.tableSelect.tablesById[tableId] : undefined
  );

  return (
    <Tooltip title="Download visible columns from the entire table">
      <span>
        <LoadingButton
          disabled={isLoading || !tableId}
          onClick={() => {
            if (
              window.confirm(
                `Downloading visible columns ${selectedTable?.rows_count} rows. Confirm?`
              )
            ) {
              const columnVisibilityModel: GridColumnVisibilityModel =
                getLocalStorageItem("columnsVisibilityModel") || {};
              dispatch(
                downloadTable(tableId!, {
                  excludedColumns: Object.keys(columnVisibilityModel).filter(
                    (key) => !columnVisibilityModel[key]
                  ),
                })
              );
            }
          }}
          loading={isLoading}
        >
          <DownloadIcon />
        </LoadingButton>
      </span>
    </Tooltip>
  );
}
