import Paper from "@mui/material/Paper";
import Scroller from "../scroller/Scroller";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

export default function TableLoading() {
  return (
    <Paper>
      <Scroller>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flex: 1,
            alignItems: "center",
          }}
        >
          <CircularProgress size={50} />
        </Box>
      </Scroller>
    </Paper>
  );
}
