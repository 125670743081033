import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import moment from "moment";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import UserAvatar from "../Avatar";
import Box from "@mui/material/Box";
import { stringToColor } from "../../utils/stringToColor";
import { useEffect, useState } from "react";
import { getGbqTables } from "./tableSelectSlice";
import PersonIcon from "@mui/icons-material/Person";
import SpacingBox from "../SpacingBox";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";

interface ITable {
  id: string;
  theme: string | undefined;
  label: string;
  date: string;
  name: string | undefined;
  expiresIn: string | false;
  parent: string;
}

const filterOptions = createFilterOptions<ITable>({
  limit: 100,
});

interface IProps {
  tableId: string;
  onChange: (tableId: string) => void;
}
export default function SourceTableSelect(props: IProps) {
  const { tableId, onChange } = props;
  const dispatch = useDispatch();

  const [onlyMyTables, setOnlyMyTables] = useState(false);

  const tables: ITable[] = useSelector((state: RootState) =>
    state.tableSelect.tablesIds
      .filter((id) => {
        const t = state.tableSelect.tablesById[id];
        return (
          !onlyMyTables ||
          t.created_by?.email === state.session.email ||
          t.table.table_id === "all_companies"
        );
      })
      .map((id) => {
        const t = state.tableSelect.tablesById[id];
        return {
          id,
          theme: t.theme,
          label: t.table.table_id,
          date: moment(t.created_at).format("YY-MM-DD hh:mm"),
          name: t.created_by?.name,
          expiresIn: !!t.expiration_time && moment(t.expiration_time).fromNow(),
          parent: t.parent_table?.table_id
            ? `from ${t.parent_table.table_id}`
            : "",
        };
      })
  );

  const selectedOption = tables.find((t) => t.id === tableId) || tables[0];
  useEffect(() => {
    if (!selectedOption) {
      dispatch(getGbqTables());
    }
  }, [dispatch, tableId]);
  if (!selectedOption) return null;
  return (
    <>
      <Autocomplete
        key={selectedOption.id}
        disableClearable
        filterOptions={filterOptions}
        fullWidth
        disablePortal
        defaultValue={selectedOption}
        size="small"
        isOptionEqualToValue={(option, value) => option.id === value.id}
        options={tables}
        renderOption={(props: any, option) => (
          <ListItemButton {...props}>
            <UserAvatar name={option.name} />
            <ListItemText
              primary={
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  {option.theme && (
                    <>
                      <Chip
                        sx={{
                          backgroundColor: stringToColor(option.theme).color,
                          marginRight: 1,
                          color: stringToColor(option.theme).textColor,
                        }}
                        size="small"
                        label={option.theme}
                      />
                    </>
                  )}
                  <span>{`${option.label} (${option.date})`}</span>
                </Box>
              }
              secondary={
                <>
                  {option.expiresIn && (
                    <span>Expires {option.expiresIn}; </span>
                  )}
                  {option.date && `Created at ${option.date}`}
                  <br />
                  {option.parent && (
                    <>
                      <span>{option.parent}</span>
                      <br />
                    </>
                  )}
                </>
              }
            />
          </ListItemButton>
        )}
        onChange={(_, option) => {
          if (option) {
            onChange(option.id);
          }
        }}
        renderInput={(params) => <TextField {...params} label="Source Table" />}
      />
      <SpacingBox />
      <Tooltip title="Show only my tables">
        <Button
          variant={onlyMyTables ? "outlined" : "text"}
          disableElevation
          onClick={() => setOnlyMyTables(!onlyMyTables)}
        >
          <PersonIcon />
        </Button>
      </Tooltip>
    </>
  );
}
