import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../../store";
import api from "../../utils/api";
import { ISliceStatus } from "../../interfaces";
import snack from "../../utils/snack";

interface IState {
  status: ISliceStatus;
}
const initialState: IState = {
  status: "idle",
};

export const tableClusteringSlice = createSlice({
  name: "tableClustering",
  initialState,
  reducers: {
    statusChanged: (state, action: PayloadAction<ISliceStatus>) => {
      state.status = action.payload;
    },
  },
});

export const { statusChanged } = tableClusteringSlice.actions;

interface IClusterTableBody {
  clustersCount: number;
  columnName: string;
  columns: string[];
  theme?: string;
}

export const clusterTable =
  (tableId: string, body: IClusterTableBody): AppThunk =>
  async (dispatch) => {
    if (!body.theme) {
      if (
        !window.confirm(
          "You didn't select a theme. Are you sure you want to continue?"
        )
      ) {
        return;
      }
    }
    dispatch(statusChanged("loading"));
    snack.info(
      "Clustering in progress. It will take several minutes. Do not close this tab."
    );
    const start = await api.fetch({
      path: `/gbqTableCluster/${tableId}`,
      method: "POST",
      body: body,
    });
    if (start.ok) {
      let jobInProgress = true;
      let errorsCount = 0;
      while (jobInProgress) {
        await new Promise((resolve) => setTimeout(resolve, 10000));
        const progress = await api.fetch({
          path: `/gbqJobStatus/${start.payload.jobId}`,
          method: "GET",
        });
        if (progress.ok) {
          jobInProgress = progress.payload.status === "RUNNING";
          errorsCount = 0;
        } else {
          errorsCount++;
          if (errorsCount > 5) {
            break;
          }
        }
      }
    }

    const finish = await api.fetch({
      path: `/gbqTableCluster/${start.payload.jobId}`,
      method: "GET",
    });

    if (finish.ok) {
      snack.info("Clustering finished! Opening resuilts in a new tab.");
      window.open(
        `/explorer?tableId=${finish.payload.destinationTableId}&theme=${body.theme}`,
        "_blank"
      );
    } else {
      snack.error("Clustering failed 😱");
      dispatch(statusChanged("error"));
    }
    dispatch(statusChanged("ready"));
  };
export default tableClusteringSlice.reducer;
