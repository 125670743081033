import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import theme from "./theme";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter } from "react-router-dom";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import Router from "./wrapper/Router";
import { Provider } from "react-redux";
import { persistor, store } from "./store";
import { SnackbarProvider } from "notistack";
import { SnackbarUtilsConfigurator } from "./utils/snack";
import { PersistGate } from "redux-persist/integration/react";
import { LicenseInfo } from "@mui/x-data-grid-premium";
LicenseInfo.setLicenseKey(
  "d25c25eefff458236f51132453950a27Tz03MTY5NSxFPTE3MjIxOTg4NTgwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <SnackbarProvider maxSnack={3}>
      <SnackbarUtilsConfigurator />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </SnackbarProvider>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
