import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "../../utils/api";
import { AppThunk } from "../../store";
import { ISliceStatus } from "../../interfaces";
import snack from "../../utils/snack";
import { getTablePreview } from "../table/tableSlice";
import { ISearchPhrase } from "../semanticSearch/semanticSearchSlice";

// [
//   {
//     kind: "bigquery#table",
//     id: "peg-sandbox:explorer.all_companies",
//     tableReference: {
//       projectId: "peg-sandbox",
//       datasetId: "explorer",
//       tableId: "all_companies",
//     },
//     type: "TABLE",
//     creationTime: "1689685206149",
//   },
// ];

interface ITable {
  parent_table?: {
    id: string;
    project_id: string;
    dataset_id: string;
    table_id: string;
    expiration_time?: number;
  };
  table: {
    id: string;
    project_id: string;
    dataset_id: string;
    table_id: string;
  };
  lost_labels?: string[];
  expiration_time?: number;
  query?: string;
  table_metadata?: string;
  grid_query?: string;
  semantic_query?: ISearchPhrase[];
  created_by?: {
    email: string;
    name: string;
  };
  created_at: number;
  rows_count: number;
  size_bytes: number;
  theme?: string;
}

interface IState {
  tablesById: { [key: string]: ITable };
  tablesIds: string[];
  status: ISliceStatus;
  themes: string[];
}

const initialState: IState = {
  tablesById: {},
  tablesIds: [],
  status: "idle",
  themes: [],
};

export const tableSelectSlice = createSlice({
  name: "tableSelect",
  initialState,
  reducers: {
    statusChanged: (state, action: PayloadAction<ISliceStatus>) => {
      state.status = action.payload;
    },
    fetched: (
      state,
      action: PayloadAction<{
        items: ITable[];
      }>
    ) => {
      if (!action.payload.items.length) {
        state.status = "empty";
      } else {
        const themes: { [theme: string]: true } = {};
        action.payload.items.forEach((item) => {
          state.tablesById[item.table.id] = item;
          if (item.theme) {
            themes[item.theme] = true;
          }
        });
        state.themes = Object.keys(themes);
        state.tablesIds = action.payload.items.map((item) => item.table.id);

        state.status = "ready";
      }
    },
  },
  extraReducers: {
    "session/logOut": () => initialState,
  },
});

export const { statusChanged, fetched } = tableSelectSlice.actions;

export const getGbqTables = (): AppThunk => async (dispatch) => {
  dispatch(statusChanged("loading"));

  const res = await api.fetch({
    path: "/gbqTables",
    method: "GET",
  });
  if (res.ok) {
    dispatch(fetched({ items: res.payload }));
  }
};

interface ISaveQueryBody {
  fromTableId: string;
  toTableName: string;
  expirationTime?: number;
}
export const saveTable =
  (body: ISaveQueryBody): AppThunk =>
  async (dispatch) => {
    dispatch(statusChanged("loading"));

    const res = await api.fetch({
      path: `/gbqTable`,
      method: "POST",
      body,
    });

    if (res.ok) {
      dispatch(getTablePreview(res.payload.destinationTableId));
      snack.success("Saved!");
      dispatch(getGbqTables());
    }
    dispatch(statusChanged("ready"));
  };

export const deleteTables =
  (tableId: string[], refresh?: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(statusChanged("loading"));

    const responses = await Promise.all(
      tableId.map((id) =>
        api.fetch({
          path: `/gbqTable/${id}`,
          method: "DELETE",
        })
      )
    );

    if (responses.every((res) => res.ok)) {
      const rip =
        tableId.length > 1
          ? `RIPs ${tableId.length} tables`
          : `RIP ${tableId[0].split(".").pop()}`;
      snack.success(`🪦 RIP ${rip}. Press F to Pay Respect.`);

      let timeout: NodeJS.Timeout;
      const listener = (e: KeyboardEvent) => {
        if (e.key === "f") {
          snack.info("🫡 👾 🎮 🕹");
          clearTimeout(timeout);
          window.removeEventListener("keydown", listener);
        }
      };
      window.addEventListener("keydown", listener);
      timeout = setTimeout(() => {
        window.removeEventListener("keydown", listener);
      }, 10000);
    }
    if (refresh) {
      dispatch(getGbqTables());
    } else {
      dispatch(statusChanged("ready"));
    }
  };

export default tableSelectSlice.reducer;
