import grey from "@mui/material/colors/grey";
import red from "@mui/material/colors/red";
import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#5D0B04",
    },
    secondary: {
      main: grey[600],
    },
    error: {
      main: red.A400,
    },
  },
});

export default theme;
