import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "../../utils/api";
import { AppThunk } from "../../store";
import { ISliceStatus } from "../../interfaces";
import { selectFile } from "../../utils/selectFile";
import snack from "../../utils/snack";

export interface INewspaper {
  _id: string;
  domain: string;
  description?: string;
  last_crawled_at?: number;
  last_finished_at?: number;
  totalRequestCount: number;
}
interface IState {
  newspapers: INewspaper[];
  status: ISliceStatus;
  exporting: boolean;
}

const initialState: IState = {
  newspapers: [],
  status: "idle",
  exporting: false,
};

export const newspapersSlice = createSlice({
  name: "newspapers",
  initialState,
  reducers: {
    statusChanged: (state, action: PayloadAction<ISliceStatus>) => {
      state.status = action.payload;
    },
    fetched: (state, action: PayloadAction<INewspaper[]>) => {
      state.newspapers = action.payload;
      state.status = "ready";
    },
    setExportingStatus: (state, action: PayloadAction<boolean>) => {
      state.exporting = action.payload;
    },
  },
  extraReducers: {
    "session/logOut": () => initialState,
  },
});

export const { statusChanged, fetched, setExportingStatus } =
  newspapersSlice.actions;

export const getNewspapers = (): AppThunk => async (dispatch) => {
  dispatch(statusChanged("loading"));
  const res = await api.fetch({
    path: "/newspapers",
    method: "GET",
  });
  if (res.ok) {
    dispatch(fetched(res.payload));
  }
};

interface IExportNewspapersCompaniesBody {
  domains: string[];
  tableName: string;
}

export const exportNewspapersCompanies =
  (body: IExportNewspapersCompaniesBody): AppThunk =>
  async (dispatch) => {
    dispatch(setExportingStatus(true));
    snack.info("Exporting...");
    const res = await api.fetch({
      path: "/newspapersCompaniesExport",
      method: "POST",
      body,
    });
    if (res.ok) {
      snack.success("Successfully exported. Redirecting...");
      window.open(
        "/explorer?tableId=peg-sandbox%3Aexplorer." + body.tableName,
        "_blank"
      );
    }
    dispatch(setExportingStatus(false));
  };

export const postNewspapers = (): AppThunk => async (dispatch) => {
  const csvString = (await selectFile()) as string;
  dispatch(statusChanged("loading"));
  if (!csvString) {
    dispatch(statusChanged("ready"));
    return;
  }

  const res = await api.fetch({
    path: "/newspapers",
    method: "POST",
    body: { csvString },
  });

  if (res.ok) {
    snack.success("Successfully submitted!");
    dispatch(getNewspapers());
  } else {
    dispatch(statusChanged("ready"));
  }
};

export default newspapersSlice.reducer;
