import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SpacingBox from "../SpacingBox";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import Collapse from "@mui/material/Collapse";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { IExplorerQuery } from "../explorer/ExplorerQuery";
import useQuery from "../../hooks/useQuery";
import { ISearchPhrase, postSemanticSearchQuery } from "./semanticSearchSlice";
import { RootState } from "../../store";
import CircularProgress from "@mui/material/CircularProgress";
import Badge from "@mui/material/Badge";

const defaultSearchPhrases: ISearchPhrase[] = [
  {
    weight: 1,
    label: "",
    searchPhrase: "",
  },
];

export default function SemanticSearchView() {
  const [isOpen, setIsOpen] = useState(false);
  const [isForceOpen, setIsForceOpen] = useState(false);
  const dispatch = useDispatch();
  const { query } = useQuery<IExplorerQuery>();
  const isLoading = useSelector(
    (state: RootState) => state.semanticSearch.status === "loading"
  );

  const { register, errors, handleSubmit, control, reset } = useForm({
    criteriaMode: "firstError",
    defaultValues: {
      searchPhrases: defaultSearchPhrases,
    },
  });
  const { fields, append, remove } = useFieldArray<ISearchPhrase>({
    control,
    name: "searchPhrases",
  });

  const table = useSelector(
    (state: RootState) => state.tableSelect.tablesById[query.tableId!]
  );
  useEffect(() => {
    reset({
      searchPhrases: table?.semantic_query?.length
        ? table.semantic_query
        : defaultSearchPhrases,
    });
  }, [table, reset]);

  if (!query.tableId) {
    return null;
  }
  return (
    <Box sx={{ width: "100%", height: 56 }}>
      <Paper
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
        sx={{
          paddingRight: 1,
          paddingLeft: 1,
          zIndex: 2,
          position: "relative",
        }}
        elevation={isOpen || isForceOpen ? 4 : 1}
      >
        <Collapse in={isOpen || isForceOpen} collapsedSize={56}>
          <SpacingBox size={1} />
          {fields.map((item, index) => (
            <Box
              key={item.id}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingBottom: 2,
              }}
            >
              {index > 0 ? (
                <IconButton onClick={() => remove(index)}>
                  <DeleteIcon />
                </IconButton>
              ) : (
                <IconButton onClick={() => setIsForceOpen(!isForceOpen)}>
                  <Badge
                    badgeContent={fields.length > 1 ? fields.length : undefined}
                    color="primary"
                  >
                    <ExpandMoreIcon
                      sx={{
                        transform: isForceOpen
                          ? "rotate(0deg)"
                          : "rotate(-90deg)",
                      }}
                    />
                  </Badge>
                </IconButton>
              )}
              <SpacingBox size={1} />
              <Controller
                as={<TextField />}
                control={control}
                sx={{
                  width: 100,
                }}
                variant="outlined"
                type="number"
                label="Weight"
                size="small"
                inputRef={register({
                  required: true,
                  pattern: {
                    value: /^-{0,1}[1-5]$/,
                    message: "Weight must be between -5 and 5 excluding 0.",
                  },
                  valueAsNumber: true,
                })}
                // helperText={errors.searchPhrases?.[index]?.weight?.message}
                error={!!errors.searchPhrases?.[index]?.weight}
                name={`searchPhrases.${index}.weight`}
              />

              <SpacingBox size={1} />
              <Controller
                control={control}
                as={<TextField />}
                sx={{ width: 160 }}
                label="Label"
                variant="outlined"
                size="small"
                name={`searchPhrases.${index}.label`}
                inputRef={register({
                  required: true,
                  pattern: {
                    value: /^[a-zA-Z0-9_]{1,30}$/,
                    message:
                      "Label must be between 1 and 30 characters and only contain alphanumeric characters and underscores.",
                  },
                })}
                // helperText={errors.searchPhrases?.[index]?.label?.message}
                error={!!errors.searchPhrases?.[index]?.label}
              />
              <SpacingBox size={1} />
              <Controller
                control={control}
                as={<TextField />}
                label="Search phrases"
                variant="outlined"
                size="small"
                fullWidth
                name={`searchPhrases.${index}.searchPhrase`}
                inputRef={register({
                  required: true,
                  maxLength: 1000,
                  minLength: 5,
                })}
                // helperText={errors.searchPhrases?.[index]?.searchPhrase?.message}
                error={!!errors.searchPhrases?.[index]?.searchPhrase}
              />
              <SpacingBox size={1} />
              {index === 0 && (
                <Box sx={{ position: "relative" }}>
                  <IconButton
                    disabled={isLoading}
                    onClick={handleSubmit((data) => {
                      dispatch(
                        postSemanticSearchQuery({
                          tableId: query.tableId!,
                          theme: query.theme,
                          searchPhrases: data.searchPhrases,
                        })
                      );
                    })}
                  >
                    <SearchIcon />
                  </IconButton>
                  {isLoading && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        opacity: 0.7,
                      }}
                    >
                      <CircularProgress
                        sx={{
                          width: "100%",
                        }}
                      />
                    </Box>
                  )}
                </Box>
              )}
              {index > 0 && index !== fields.length - 1 && (
                <Box sx={{ width: 56 }} />
              )}
              {index > 0 && index < 10 && index === fields.length - 1 && (
                <IconButton
                  onClick={() =>
                    append({
                      weight: 1,
                      label: "",
                      searchPhrase: "",
                    })
                  }
                >
                  <AddIcon />
                </IconButton>
              )}
            </Box>
          ))}
          {fields.length < 2 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Box sx={{ flexGrow: 1 }} />

              <IconButton
                sx={{ marginBottom: 1 }}
                onClick={() => {
                  append({
                    weight: 1,
                    label: "",
                    searchPhrase: "",
                  });
                }}
              >
                <AddIcon />
              </IconButton>
            </Box>
          )}
        </Collapse>
      </Paper>
    </Box>
  );
}
