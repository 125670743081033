import Box from "@mui/material/Box";
import { IExplorerQuery } from "../explorer/ExplorerQuery";
import useQuery from "../../hooks/useQuery";
import { useDispatch, useSelector } from "react-redux";
import Paper from "@mui/material/Paper";
import { useForm } from "react-hook-form";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import SpacingBox from "../SpacingBox";
import { useEffect, useMemo, useState } from "react";
import { RootState } from "../../store";
import Autocomplete from "@mui/material/Autocomplete";
import uniq from "lodash/uniq";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "../../hooks/useLocalStorage";
import { clusterTable } from "./tableClusteringSlice";
import { tableToType } from "../../utils/tableToType";

interface IProps {
  handlePopperClose: () => void;
}

export default function TableClusteringForm(props: IProps) {
  const { query } = useQuery<IExplorerQuery>();
  const { tableId } = query;
  const dispatch = useDispatch();

  const isEntitiesTable = useMemo(
    () => tableToType(tableId) === "entities",
    [tableId]
  );
  const semanticsColumn = useMemo(
    () => (isEntitiesTable ? "context" : "semantic_keywords"),
    [isEntitiesTable]
  );

  const columns = useSelector((state: RootState) => [
    semanticsColumn,
    ...state.table.columns.map((c) => c.field),
  ]);
  const defaultColumns = getLocalStorageItem("classificationColumns") || [
    semanticsColumn,
  ];
  const { register, getValues, setValue, errors, handleSubmit } = useForm<{
    clustersCount: number;
    columnName: string;
    columns: string[];
  }>({
    defaultValues: {
      clustersCount: 10,
      columns: defaultColumns,
    },
  });

  const [considerKeywords, setConsiderKeywords] = useState(false);

  useEffect(() => {
    register("columns");
  }, []);

  if (!tableId) {
    return null;
  }

  return (
    <Paper elevation={8}>
      <Box
        sx={{
          p: 2,
        }}
      >
        <Typography variant="h5">Clustering</Typography>
      </Box>
      <Divider />
      <Box
        sx={{
          p: 2,
        }}
      >
        <TextField
          label="Clusters count between 2 and 100"
          name="clustersCount"
          size="small"
          sx={{ flex: 1 }}
          type="number"
          fullWidth
          // defaultValue={10}
          inputRef={register({
            required: true,
            validate: (value) => value >= 2 && value <= 100,
          })}
          placeholder={`10`}
          error={!!errors.clustersCount}
        />

        <SpacingBox />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <TextField
            label="Clustering name"
            name="columnName"
            size="small"
            sx={{ flex: 1 }}
            fullWidth
            defaultValue={query.theme}
            inputRef={register({
              required: true,
              pattern: /^[a-z0-9_]+$/,
              maxLength: 128,
              minLength: 5,
            })}
            placeholder={`my_awesome_clustering`}
            error={!!errors.columnName}
          />
          <SpacingBox />
          <Tooltip title='If checked, then the clustering model will use all counted keywords (columns starting with "_") in addition to the columns you selected.'>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Checkbox
                value={considerKeywords}
                onChange={() => {
                  setConsiderKeywords(!considerKeywords);
                }}
              />
              <Typography>Consider counted keywords</Typography>
            </Box>
          </Tooltip>
        </Box>
        <SpacingBox />
        <Autocomplete
          multiple
          options={columns}
          size="small"
          onChange={(_, value) => {
            setValue("columns", value);
            setLocalStorageItem("classificationColumns", value);
          }}
          defaultValue={defaultColumns}
          filterSelectedOptions
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip label={option} {...getTagProps({ index })} />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select columns to consider"
              placeholder="Select columns to consider"
              helperText="WARNING: use only numeric and categorical columns. Plain text columns are not supported. The only exception is the website home page text and the semantic keywords column."
            />
          )}
        />
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          p: 1,
          justifyContent: "space-between",
        }}
      >
        <Button onClick={props.handlePopperClose}>Cancel</Button>
        <Button
          onClick={handleSubmit((data) => {
            if (data.columnName !== query.theme) {
              if (
                !window.confirm(
                  "The clustering name is different from the theme name. This is an antipattern 👎. Are you sure?"
                )
              ) {
                return;
              }
            }
            const keywordsColumns = considerKeywords
              ? columns.filter((c) => c.startsWith("_"))
              : [];
            let selectedColumns = getValues().columns;
            if (typeof selectedColumns === "string") {
              selectedColumns = [selectedColumns as string];
            }
            const columnsToConsider = uniq(
              selectedColumns.concat(keywordsColumns)
            );
            dispatch(
              clusterTable(tableId, {
                ...data,
                columns: columnsToConsider,
                theme: query.theme,
              })
            );
            props.handlePopperClose();
          })}
        >
          Start clustering
        </Button>
      </Box>
    </Paper>
  );
}
