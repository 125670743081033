import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRole } from "../utils/roles";
import api from "../utils/api";
import { AppThunk } from "../store";
import { Buffer } from "buffer";
import snack from "../utils/snack";

type IStatus = "idle" | "loading";
interface sessionState {
  isLoggedIn: boolean;
  authToken?: string;
  name?: string;
  email?: string;
  role?: IRole;
  status: IStatus;
}

interface ILoggedInPayload {
  token: string;
  name: string;
  email: string;
  role: IRole;
}

const initialState: sessionState = {
  isLoggedIn: false,
  status: "idle",
};

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    logOut: (state) => {
      state.isLoggedIn = false;
      api.config.setToken();
      state.authToken = undefined;
    },
    loggedIn: (state, action: PayloadAction<ILoggedInPayload>) => {
      state.status = "idle";
      state.authToken = action.payload.token;
      state.name = action.payload.name;
      state.role = action.payload.role;
      state.email = action.payload.email;
      state.isLoggedIn = true;
    },
    setStatus: (state, action: PayloadAction<IStatus>) => {
      state.status = action.payload;
    },
  },
});

export const { loggedIn, logOut, setStatus } = sessionSlice.actions;

export const logInWithPassword =
  (phone: string, otp: string): AppThunk =>
  async (dispatch) => {
    dispatch(setStatus("loading"));
    const res = await api.fetch({
      path: "/login",
      method: "POST",
      headers: {
        Authorization: `Basic ${Buffer.from(`${phone}:${otp}`).toString(
          "base64"
        )}`,
      },
    });
    if (res.token) {
      api.config.setToken(res.token);
      dispatch(
        loggedIn({
          token: res.token,
          name: res.payload.name,
          role: res.payload.role,
          email: res.payload.email,
        })
      );
    }
    dispatch(setStatus("idle"));
  };

export const mfa =
  (phone: string, password: string): AppThunk =>
  async (dispatch) => {
    dispatch(setStatus("loading"));
    const res = await api.fetch({
      path: "/mfa",
      method: "POST",
      body: {
        phone,
        password,
      },
    });
    if (res.ok) {
      snack.info("Check your phone for a code");
    }
    dispatch(setStatus("idle"));
  };

export const checkToken = (): AppThunk => async (dispatch, getState) => {
  const isLoggedIn = getState().session.isLoggedIn;
  const token = window.localStorage.getItem("token");
  if (isLoggedIn && !token) {
    dispatch(logOut());
  }
};

export default sessionSlice.reducer;
