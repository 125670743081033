import { GridToolbarContainer } from "@mui/x-data-grid/components/containers/GridToolbarContainer";
import Divider from "@mui/material/Divider";
import Box from "@mui/system/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import {
  exportNewspapersCompanies,
  getNewspapers,
  postNewspapers,
} from "./newspapersSlice";
import { downloadString } from "../../utils/downloadString";
import { Tooltip } from "@mui/material";
import snack from "../../utils/snack";
import {
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-premium";

const downloadSample = () =>
  downloadString(
    "newspapers input.csv",
    "domain,description\njuneauempire.com,batch name or any other short alphanumeric description"
  );

interface IProps {
  getSelectedRows: () => string[];
}
export function NewspapersToolbar(props: IProps) {
  const isLoading = useSelector(
    (state: RootState) => state.newspapers.status === "loading"
  );
  const isExporting = useSelector(
    (state: RootState) => state.newspapers.exporting
  );
  const dispatch = useDispatch();
  return (
    <>
      <GridToolbarContainer sx={{ padding: 1 }}>
        <IconButton
          disabled={isLoading}
          onClick={() => dispatch(getNewspapers())}
        >
          <RefreshIcon />
        </IconButton>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <Tooltip title="Export companies found on the selected newspaper pages to the Explorer">
          <LoadingButton
            loading={isExporting}
            onClick={() => {
              const selectedRows = props.getSelectedRows();
              if (!selectedRows.length) {
                snack.error("No rows selected");
                return;
              }
              const tableName =
                window.prompt(
                  'Give the output table a meaningful name. Only lowercase letters and underscores. From 5 to 200 characters long. E.g. "insurance_companies_newspapers"'
                ) || "";
              const tableNameRegex = /^[a-z_]{5,200}$/;
              if (!tableNameRegex.test(tableName)) {
                snack.error("Invalid table name");
                return;
              }
              dispatch(
                exportNewspapersCompanies({ domains: selectedRows, tableName })
              );
            }}
          >
            Export selected
          </LoadingButton>
        </Tooltip>

        <Box sx={{ flexGrow: 1 }} />
        <Button variant="outlined" onClick={downloadSample}>
          Download input sample
        </Button>
        <Button variant="outlined" onClick={() => dispatch(postNewspapers())}>
          Upload input CSV
        </Button>
      </GridToolbarContainer>
      <Divider />
    </>
  );
}
