import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "../../utils/api";
import { AppThunk } from "../../store";
import { ISliceStatus } from "../../interfaces";

type GenericObject = { [key: string]: any };

interface IModel {
  _id: string;
  id: string;
  model_type: string;
  created_by: {
    email: string;
    name: string;
  };
  created_at: number;
  modified_at: number;
  theme: string;
  precision: number;
  recall: number;
  accuracy: number;
  threshold: number;
  f1_score: number;
  log_loss: number;
  roc_auc: number;
  feature_columns: GenericObject[];
}

interface IState {
  models: IModel[];
  status: ISliceStatus;
  exporting: boolean;
}

const initialState: IState = {
  models: [],
  status: "idle",
  exporting: false,
};

export const modelsSlice = createSlice({
  name: "models",
  initialState,
  reducers: {
    statusChanged: (state, action: PayloadAction<ISliceStatus>) => {
      state.status = action.payload;
    },
    fetched: (state, action: PayloadAction<any[]>) => {
      state.models = action.payload;
      state.status = "ready";
    },
    setExportingStatus: (state, action: PayloadAction<boolean>) => {
      state.exporting = action.payload;
    },
  },
  extraReducers: {
    "session/logOut": () => initialState,
  },
});

export const { statusChanged, fetched, setExportingStatus } =
  modelsSlice.actions;

export const getModels = (): AppThunk => async (dispatch) => {
  dispatch(statusChanged("loading"));
  const res = await api.fetch({
    path: "/gbqModels",
    method: "GET",
  });
  if (res.ok) {
    dispatch(fetched(res.payload));
  }
};

export default modelsSlice.reducer;
