import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import Popper from "@mui/material/Popper";
import { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import TableClassificationForm from "./TableClassificationForm";

export default function ClassifyTablePoper() {
  const isLoading = useSelector(
    (state: RootState) => state.tableClassification.status === "loading"
  );
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopperOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopperClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box>
      <Box>
        <LoadingButton
          disabled={isLoading}
          loading={isLoading}
          onClick={handlePopperOpen}
        >
          Classify
        </LoadingButton>
      </Box>

      <Popper sx={{ zIndex: 3, width: 550 }} open={open} anchorEl={anchorEl}>
        <TableClassificationForm handlePopperClose={handlePopperClose} />
      </Popper>
    </Box>
  );
}
