import { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";

interface IOption {
  key: string;
  label: string;
}
interface IMultiselectProps {
  label: string;
  options?: string[] | IOption[];
  selected?: string[];
  onSelect: (value: string[]) => void;
}

const anyLabel = "None";
export function MultiSelect(props: IMultiselectProps) {
  const { options = [], selected = [anyLabel], onSelect, label } = props;

  const menuItems: IOption[] = options.map((item) =>
    typeof item === "string" ? { key: item, label: item } : item
  );
  return (
    <FormControl
      fullWidth
      sx={{
        minWidth: 140,
      }}
      variant="outlined"
      size="small"
    >
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        value={selected}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
        onChange={(e: SelectChangeEvent<typeof selected>) => {
          const value = (e.target.value as string[]) || [];
          onSelect(value);
          if (value[value.length - 1] === anyLabel) {
            onSelect([]);
          } else {
            onSelect(value.filter((val) => val !== anyLabel));
          }
        }}
        defaultValue={[anyLabel]}
        label={label}
      >
        <MenuItem value={anyLabel}>{anyLabel}</MenuItem>
        {menuItems.map((item) => (
          <MenuItem key={item.key} value={item.key}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

interface ISelectProps {
  label: string;
  options?: string[] | IOption[];
  onSelect: (value?: string) => void;
  noDefault?: boolean;
  defaultValue?: string;
  fullWidth?: boolean;
  disabled?: boolean;
}

export function SingleSelect(props: ISelectProps) {
  const {
    options = [],
    defaultValue,
    onSelect,
    label,
    noDefault,
    fullWidth = true,
    disabled,
  } = props;
  const menuItems: IOption[] = options.map((item) =>
    typeof item === "string" ? { key: item, label: item } : item
  );
  const [value, setvalue] = useState<string>(defaultValue || anyLabel);
  return (
    <FormControl
      sx={{
        minWidth: 140,
      }}
      variant="outlined"
      size="small"
      fullWidth={fullWidth}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        value={value}
        disabled={disabled}
        defaultValue={anyLabel}
        onChange={(e) => {
          if (e.target.value === anyLabel) {
            setvalue(anyLabel);
            onSelect();
          } else {
            setvalue(e.target.value as string);
            onSelect(e.target.value as string);
          }
        }}
        label={label}
      >
        {!noDefault && <MenuItem value={anyLabel}>{anyLabel}</MenuItem>}
        {menuItems.map((item) => (
          <MenuItem key={item.key} value={item.key}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
