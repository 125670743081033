import Joi from "joi";
import { batchOptions } from "./batchesDict";
import moment from "moment";

export interface IBatchesQuery {
  batchType?: string;
  period?: string;
  searchPhrase?: string;
  notDownloadedOnly?: boolean;
}

const periods = Array.from(
  { length: moment().diff(moment("2022-06", "YYYY-MM"), "months") },
  (_, i) => moment().subtract(i, "months").format("YYYY-MM")
);

export const periodOptions = [
  {
    key: "all",
    label: "All",
  },
].concat(
  periods.map((item) => ({
    key: item,
    label: item,
  }))
);

export const batchesQuerySchema = Joi.object({
  batchType: Joi.string().valid("all", ...batchOptions.map((item) => item.key)),
  period: Joi.string().valid("all", ...periods),
  searchPhrase: Joi.string().max(255),
  notDownloadedOnly: Joi.boolean(),
});
