import { useCallback } from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { batchOptions } from "./batchesDict";
import { SingleSelect } from "../select/Select";
import TextField from "@mui/material/TextField";
import { debounce } from "lodash";
import SpacingBox from "../SpacingBox";
import useQuery from "../../hooks/useQuery";
import { IBatchesQuery, periodOptions } from "./batchesQuery";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DownloadingIcon from "@mui/icons-material/Downloading";

export default function BatchesFilters() {
  const { query, setQuery } = useQuery<IBatchesQuery>();

  const theme = useTheme();

  const debounced = useCallback(
    debounce(
      (value) =>
        setQuery({
          searchPhrase: value.toLowerCase(),
        }),
      500
    ),
    []
  );

  return (
    <Box
      sx={{
        padding: theme.spacing(2),
        flexDirection: "row",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Tooltip
        title={
          "Hide downloaded batches" +
          (query.notDownloadedOnly ? " (active)" : "")
        }
      >
        <IconButton
          onClick={() =>
            setQuery({
              notDownloadedOnly: !query.notDownloadedOnly,
            })
          }
          color={query.notDownloadedOnly ? "primary" : "default"}
        >
          <DownloadingIcon />
        </IconButton>
      </Tooltip>
      <SpacingBox />
      <SingleSelect
        noDefault
        label="Batch type"
        options={[{ key: "all", label: "All" }, ...batchOptions]}
        defaultValue={query.batchType || "all"}
        fullWidth={false}
        onSelect={(val) =>
          setQuery({ batchType: val === "all" ? undefined : val! })
        }
      />
      <SpacingBox />
      <SingleSelect
        noDefault
        label="Period"
        fullWidth={false}
        options={periodOptions}
        defaultValue={query.period || "all"}
        onSelect={(val) =>
          setQuery({ period: val === "all" ? undefined : val! })
        }
      />
      <SpacingBox />
      <TextField
        label="Search by name"
        variant="outlined"
        size="small"
        defaultValue={query.searchPhrase || ""}
        onChange={(e) => debounced(e.target.value)}
      />
    </Box>
  );
}
