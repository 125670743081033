import { useState } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { MultiSelect, SingleSelect } from "../select/Select";
import { useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useDispatch } from "react-redux";
import { postBatch } from "./batchesSlice";
import snack from "../../utils/snack";
import { batchesByKey, batchOptions } from "./batchesDict";
import SpacingBox from "../SpacingBox";
import FormHelperText from "@mui/material/FormHelperText";
import { selectFile } from "../../utils/selectFile";

const flagsDict: { [key: string]: string } = {
  skipApi: "Skip all API stages",
  ignoreFlags: "⚠️ Ignore all disqualification flags",
  noCache: "⚠️ Do not use cache",
};

interface IBatchForm {
  batchName: string;
  batchData: string;
}
export default function BatchFormView() {
  const { register, errors, handleSubmit, reset, setValue } =
    useForm<IBatchForm>();
  const [batchType, setBatchType] = useState("websites");
  const { selectLabel, label, helperText, placeholder } =
    batchesByKey[batchType];
  const theme = useTheme();
  const [resetKey, setResetKey] = useState("a");
  const [batchFlags, setBatchFlags] = useState<string[]>(["skipApi"]);
  const dispatch = useDispatch();
  const handleBatchSubmit = (batch: IBatchForm) => {
    dispatch(
      postBatch(
        { ...batch, batchType, batchFlags: batchFlags.join(",") },
        () => {
          reset();
          setResetKey(String(Math.random()));
          setBatchFlags([]);
        }
      )
    );
  };
  return (
    <Paper
      sx={{
        maxWidth: 375,
        width: 375,
        minWidth: 375,
      }}
    >
      <Box
        sx={{
          padding: theme.spacing(2),
          flexDirection: "row",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h5">Submit new batch</Typography>
      </Box>
      <Divider />
      <Box
        sx={{
          padding: theme.spacing(2),
        }}
        key={resetKey}
      >
        <TextField
          error={!!errors.batchName}
          name="batchName"
          inputRef={register({
            required: true,
            pattern: {
              value: /^[a-zA-Z0-9 -_]{1,30}$/,
              message:
                "The name should include between 1 and 30 letters, numbers, spaces, dashes and underscores",
            },
          })}
          label="Batch name"
          helperText={errors.batchName?.message}
          fullWidth
          size="small"
          variant="outlined"
        />
        <SpacingBox />
        <SingleSelect
          noDefault
          label="Batch type"
          options={batchOptions.filter(
            (item) => batchesByKey[item.key].submittable
          )}
          defaultValue={"websites"}
          onSelect={(val) => setBatchType(val!)}
        />
        <SpacingBox />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Button
            disabled={false}
            onClick={async () => {
              const text = await selectFile();
              setValue("batchData", text);
            }}
            variant="outlined"
          >
            Upload CSV
          </Button>
          <SpacingBox />
          <Typography>or just copy-paste here:</Typography>
        </Box>
        <SpacingBox />
        <TextField
          multiline
          rows={5}
          error={!!errors.batchData}
          name="batchData"
          InputLabelProps={{ shrink: true }}
          inputRef={register({
            required: true,
          })}
          label={label}
          placeholder={placeholder}
          helperText={errors.batchData?.message || helperText}
          fullWidth
          size="small"
          variant="outlined"
        />
        {batchesByKey[batchType].downloadInputTemplate && (
          <>
            <SpacingBox />
            <Button
              disabled={false}
              onClick={batchesByKey[batchType].downloadInputTemplate}
              variant="outlined"
              fullWidth
            >
              Download CSV input template
            </Button>
          </>
        )}
        <SpacingBox />
        <MultiSelect
          label="Batch flags"
          options={Object.keys(flagsDict).map((key) => ({
            key,
            label: flagsDict[key],
          }))}
          selected={batchFlags}
          onSelect={(vals) => setBatchFlags(vals)}
        />

        {batchFlags.includes("ignoreFlags") && (
          <FormHelperText>
            All companies in this batch will be forever excluded from
            disqualification. They will be passed to all expensive API steps
            however many companies there are.
          </FormHelperText>
        )}
      </Box>
      <Divider />
      <Box
        sx={{
          padding: theme.spacing(2),
          flexDirection: "column",
          display: "flex",
        }}
      >
        <Button
          disabled={false}
          onClick={handleSubmit((e) => {
            if (
              window.confirm(
                `You are submitting ${e.batchData
                  .split("\n")
                  .length.toLocaleString()} lines as "${selectLabel}" batch named "${
                  e.batchName
                }" with ${
                  batchFlags.length ? batchFlags.length : "no"
                } flags. Confirm?`
              )
            ) {
              handleBatchSubmit(e);
            }
          })}
          variant="outlined"
          fullWidth
        >
          Submit Batch
        </Button>
      </Box>
      <Divider />
    </Paper>
  );
}
