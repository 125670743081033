import TextField from "@mui/material/TextField";
import {
  GridFilterInputValue,
  GridFilterInputValueProps,
  GridFilterOperator,
  getGridStringOperators,
} from "@mui/x-data-grid-premium";
import React from "react";
import debounce from "lodash/debounce";

export const gridStringOperators: GridFilterOperator[] = [
  {
    label: "not contains",
    value: "notContains",
    getApplyFilterFn: () => null, // because we use server-side filtering
    InputComponent: GridFilterInputValue,
    InputComponentProps: { type: "string" },
    getValueAsString: (value: string) => value,
  },
  ...getGridStringOperators().filter((item) => item.value !== "isAnyOf"),
  {
    label: "is any of",
    value: "isAnyOf",
    getApplyFilterFn: () => null, // because we use server-side filtering
    InputComponent: AnyOfInput,
    InputComponentProps: { type: "string" },
    getValueAsString: (value: string[]) => value.join(", "),
  },
  {
    label: "is none of",
    value: "isNoneOf",
    getApplyFilterFn: () => null, // because we use server-side filtering
    InputComponent: AnyOfInput,
    InputComponentProps: { type: "string" },
    getValueAsString: (value: string[]) => value.join(", "),
  },
];

function AnyOfInput(props: GridFilterInputValueProps) {
  const { item, applyValue } = props;
  const [value, setValue] = React.useState<string>(item.value?.join("\n"));

  // debounced applyValue
  const applyValueDebounced = React.useCallback(
    debounce((value: string) => {
      const cleanVals = value.split("\n").map((v) => v.trim());
      applyValue({ ...item, value: cleanVals });
    }, 500),
    [applyValue]
  );

  return (
    <TextField
      value={value}
      label="Linebreak-separated values"
      variant="standard"
      multiline
      maxRows={4}
      onChange={(e) => {
        const value = e.target.value;
        setValue(value);
        applyValueDebounced(value);
      }}
    />
  );
}
