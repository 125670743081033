import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import useQuery from "../../hooks/useQuery";
import Box from "@mui/material/Box";
import SpacingBox from "../SpacingBox";
import Tooltip from "@mui/material/Tooltip";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IExplorerQuery, explorerQuerySchema } from "../explorer/ExplorerQuery";
import SourceTableSelect from "./SourceTableSelect";
import SaveTablePopper from "../saveTablePopper/SaveTablePopper";
import { Link } from "react-router-dom";
import qs from "qs";
import Button from "@mui/material/Button";
import TableSelectRefresh from "./TableSelectRefresh";
import TableSelectDelete from "./TableSelectDelete";

export default function TableSelect() {
  const { query, setQuery } = useQuery<IExplorerQuery>(explorerQuerySchema);
  const { tableId } = query;

  const isLoading = useSelector(
    (state: RootState) => state.tableSelect.status === "loading"
  );

  const firstTableId = useSelector(
    (state: RootState) => state.tableSelect.tablesIds[0]
  );

  const selectedTable = useSelector((state: RootState) =>
    tableId ? state.tableSelect.tablesById[tableId] : undefined
  );

  useEffect(() => {
    if (!tableId) {
      setQuery({
        tableId: tableId || firstTableId,
        paginationModel: undefined,
      });
    }
  }, [tableId, setQuery]);
  if (!tableId) {
    return null;
  }

  const parentId =
    selectedTable?.parent_table && !selectedTable?.parent_table.expiration_time
      ? selectedTable?.parent_table?.id
      : selectedTable?.parent_table?.expiration_time! > Date.now()
      ? selectedTable?.parent_table?.id
      : undefined;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        flex: 1,
      }}
    >
      <Tooltip
        title={
          parentId
            ? `Go back to the parent table: ${parentId}`
            : selectedTable?.parent_table
            ? "Parent table expired"
            : "No parent table"
        }
      >
        <span>
          <Button
            disabled={isLoading || !parentId}
            component={Link}
            to={{
              search: qs.stringify({
                ...query,
                tableId: parentId,
                paginationModel: undefined,
              }),
            }}
          >
            <ArrowBackIcon />
          </Button>
        </span>
      </Tooltip>
      <SpacingBox size={1} />
      <SourceTableSelect
        tableId={tableId}
        onChange={(tableId) => {
          setQuery({
            tableId,
            paginationModel: undefined,
          });
        }}
      />

      <SpacingBox size={1} />
      <TableSelectDelete />

      <SpacingBox size={1} />
      <TableSelectRefresh />

      <SpacingBox size={1} />
      <SaveTablePopper tableId={selectedTable?.table.id} />
    </Box>
  );
}
