import React from "react";
import lottie from "lottie-web";
import Box from "@mui/material/Box";

export default function NotFoundView() {
  React.useEffect(() => {
    lottie.loadAnimation({
      container: document.getElementById("404")!, // the dom element that will contain the animation
      renderer: "svg",
      autoplay: true,
      loop: false,
      path: "404.json", // the path to the animation json
    });
  }, []);
  return (
    <Box
      id="404"
      sx={{
        maxHeight: "80vh",
        flex: 1,
        alignSelf: "center",
      }}
    ></Box>
  );
}
