import { ITableMetadata } from "./tableSlice";

const numericFields = [
  "numBytes",
  "numLongTermBytes",
  "numRows",
  "creationTime",
  "lastModifiedTime",
  "numTimeTravelPhysicalBytes",
  "numTotalLogicalBytes",
  "numActiveLogicalBytes",
  "numLongTermLogicalBytes",
  "numTotalPhysicalBytes",
  "numActivePhysicalBytes",
  "numLongTermPhysicalBytes",
];
const parseGbqMetadata = (metadata: any) => {
  for (const i in numericFields) {
    const key = numericFields[i];
    if (metadata[key]) {
      metadata[key] = parseInt(metadata[key]);
    }
  }

  return metadata as ITableMetadata;
};

export default parseGbqMetadata;
