import { useLocation, useNavigate } from "react-router-dom";
import qs from "qs";
import Joi from "joi";
import {
  GridColumnVisibilityModel,
  GridFilterItem,
  GridFilterModel,
  GridPaginationModel,
  GridSortModel,
} from "@mui/x-data-grid-premium";
import snack from "../utils/snack";

export interface ICompaniesTableQuery {
  pageNumber?: number;
  filters?: GridFilterItem[];
  hiddenColumns?: string[];
  afterRow?: string;
  beforeRow?: string;
}

export interface ISearchQuery {
  alpha?: number;
  searchPhrase?: string;
  pageSize?: number;
}

export default function useQuery<T>(schema?: Joi.Schema): {
  query: T;
  setQuery: (query: Partial<T>) => void;
  pushQuery: (query: Partial<T>) => void;
} {
  const { pathname, search } = useLocation();
  let query = qs.parse(search, {
    ignoreQueryPrefix: true,
    allowPrototypes: true,
  });
  const navigate = useNavigate();
  function setQuery(newQueryPiece: Partial<T>) {
    const newQuery = { ...query, ...newQueryPiece };
    Object.keys(newQueryPiece).forEach((key) => {
      if (!newQuery[key] || newQuery[key] === "undefined") {
        delete newQuery[key];
      }
    });

    navigate(
      {
        pathname,
        search: `?${qs.stringify(newQuery)}`,
      },
      { replace: true }
    );
  }
  function pushQuery(newQueryPiece: Partial<T>) {
    const newQuery = { ...query, ...newQueryPiece };
    Object.keys(newQueryPiece).forEach((key) => {
      if (!newQuery[key] || newQuery[key] === "undefined") {
        delete newQuery[key];
      }
    });
    navigate({
      pathname,
      search: `?${qs.stringify(newQuery)}`,
    });
  }

  if (schema) {
    try {
      query = Joi.attempt(query, schema);
    } catch (e) {
      console.error(e);
      query = {};
      snack.error(`Invalid url query: ${e}`);
      navigate({ pathname, search: "" });
    }
  }
  return {
    // @ts-ignore
    query,
    setQuery,
    pushQuery,
  };
}
