import { downloadString } from "../../utils/downloadString";

interface IBatchAnnotations {
  [batchType: string]: {
    selectLabel: string;
    key: string;
    label: string;
    helperText: string;
    placeholder?: string;
    submittable?: boolean;
    canPushToClay?: boolean;
    downloadInputTemplate?: () => void;
  };
}
export const batchesByKey: IBatchAnnotations = {
  websites: {
    selectLabel: "Companies websites",
    key: "websites",
    label: "List of domains",
    helperText:
      'Good: "example.com" Bad: "http://example.com", "www.example.com"',
    submittable: true,
    canPushToClay: true,
    placeholder: `d1training.com\nmassageenvy.com\n...`,
  },
  orgs: {
    selectLabel: "Companies names",
    key: "orgs",
    label: "List of companies names",
    submittable: true,
    placeholder: `Facebook\nApple\n...`,
    helperText: 'E.g. "Apple"',
  },
  orgs_serp: {
    selectLabel: "Companies names in Bing",
    key: "orgs_serp",
    label: "List of companies names",
    submittable: true,
    placeholder: `Facebook\nApple\n...`,
    helperText: 'E.g. "Apple"',
  },
  newsWebsites: {
    selectLabel: "Full news websites",
    key: "newsWebsites",
    label: "List of domains",
    helperText:
      'Good: "example.com" Bad: "http://example.com", "www.example.com"',
    submittable: false,
    placeholder: `techcrunch.com\nscientificamerican.com\n...`,
  },
  yelpIndustries: {
    selectLabel: "Yelp themes",
    key: "yelpIndustries",
    label: "List of themes keywrods",
    helperText: 'E.g.: "oil change"',
    submittable: true,
    placeholder: `oil change\ncar wash\n...`,
  },
  "serp-industries": {
    selectLabel: "SERP themes",
    key: "serp-industries",
    label: "List of themes keywrods",
    helperText: 'E.g.: "oil change"',
    submittable: true,
    placeholder: `oil change\ncar wash\n...`,
  },
  articles: {
    selectLabel: "News articles URLs",
    key: "articles",
    label: "List of news article URLs",
    helperText: 'Good: "http://example.com/some-article',
    submittable: true,
    placeholder: `http://example.com/some-article\nhttp://example.com/another-article\n...`,
  },
  people: {
    selectLabel: "People names",
    key: "people",
    label: "List of people names and companies",
    submittable: true,
    placeholder: `Steve Jobs, Apple\nJoe Rogan\nBill Gates, Microsoft\n...`,
    helperText:
      'Format: "<person_name><comma><company_name>". Company name is optional. E.g. "Steve Jobs, Apple"',
  },
  screenshots: {
    selectLabel: "Screenshots",
    key: "screenshots",
    label: "List of urls",
    helperText: 'Good: "http://example.com/something',
    submittable: true,
    placeholder: `http://example.com\nhttp://example.com/some-page\nhttp://another-example.com\n...`,
  },
  images: {
    selectLabel: "Images",
    key: "images",
    submittable: true,
    label: "CSV with 2 columns: url and key",
    helperText:
      "Key doesn't have to be unique. When you download results the images will be named as <key>-<random_id>.jpg",
    downloadInputTemplate: () =>
      downloadString(
        "images_input.csv",
        "key,url\ncarolinafacialplasticsurgery.com,https://lh5.googleusercontent.com/p/AF1QipM_0JkK36td4zLjsTr6nEZFUq5fWjJMRtaKtD9h=w1600-h1000-k-no"
      ),
  },
  rss: {
    selectLabel: "Weekly RSS",
    key: "rss",
    label: "",
    helperText: "",
    placeholder: "",
  },
  linkedinCompanies: {
    selectLabel: "Linkedin companies' urls",
    key: "linkedinCompanies",
    label: "List of linkedin urls",
    helperText:
      'Good: "http://www.linkedin.com/company/605data" Bad: "linkedin.com/company/605data"',
    submittable: false,
    canPushToClay: false,
    placeholder: `http://www.linkedin.com/company/605datahttp://www.linkedin.com/company/d1training\n...`,
  },
  coresignalCompanies: {
    selectLabel: "Coresignal companies",
    key: "coresignalCompanies",
    label: "CSV with headers and search params",
    canPushToClay: true,
    helperText:
      "One or several columns: max_results, name, website, created_at_gte, created_at_lte, last_updated_gte, last_updated_lte, employees_count_gte, employees_count_lte, linkedin_url, description, industry, specialties,id",
    submittable: true,
    downloadInputTemplate: () =>
      downloadString(
        "coresignal_companies_input.csv",
        'max_results,name,website,created_at_gte,created_at_lte,last_updated_gte,last_updated_lte,employees_count_gte,employees_count_lte,linkedin_url,description,industry,specialties,id,founded\n1,"Panoramic Health","panoramichealth.com","2016-06-20 00:00:00","2022-06-23 00:00:00","2023-01-16 00:00:00","2023-09-18 00:00:00","100","1000","linkedin.com/company/global-nephrology-solutions-llc","kidney,analytics,data warehouses","Health Care","nephrology,kidney,Renal Care Organization","55669258",2016'
      ),
  },
  coresignalPeople: {
    selectLabel: "Coresignal people",
    key: "coresignalPeople",
    label: "CSV with headers and search params",
    helperText:
      "One or several columns: max_results, member_shorthand_name, name, title, location, industry, summary, created_at_gte, created_at_lte, last_updated_gte, last_updated_lte, country, skill, certification_name, experience_title, experience_company_name, experience_company_exact_name, experience_company_website_url, experience_company_website_exact_url, experience_company_linkedin_url, experience_company_industry, experience_date_from, experience_date_to, experience_description, active_experience, keyword",
    submittable: true,
    downloadInputTemplate: () =>
      downloadString(
        "coresignal_people_input.csv",
        'max_results,member_shorthand_name,name,title,location,industry,summary,created_at_gte,created_at_lte,last_updated_gte,last_updated_lte,country,skill,certification_name,experience_title,experience_company_name,experience_company_exact_name,experience_company_website_url,experience_company_website_exact_url,experience_company_linkedin_url,experience_company_industry,experience_date_from,experience_date_to,experience_description,active_experience,keyword\n1,kelly-atkinson-1b885118,Kelly Atkinson,CEO,United States,Restaurants,CEO,2020-01-01 00:00:00,2023-01-01 00:00:00,2020-01-01 00:00:00,2023-01-01 00:00:00,United States,Microsoft Office,Certified,CEO,Zaxby,"Zaxby Franchising, Inc.",https://www.zaxbys.com/,https://www.zaxbys.com/,https://www.linkedin.com/company/zaxbys,Restaurants,January 2020,January 2024,I am a manager at Zaxby,TRUE,zaxby'
      ),
  },
  "similar-companies": {
    selectLabel: "Companies mentioned in the same news",
    key: "similar-companies",
    label: "List of domains",
    helperText:
      'Good: "example.com" Bad: "http://example.com", "www.example.com"',
    submittable: true,
    placeholder: `d1training.com\nmassageenvy.com\n...`,
  },
};

export const batchOptions = Object.keys(batchesByKey).map((key) => ({
  label: batchesByKey[key].selectLabel,
  key,
}));
