import { GridPaginationModel } from "@mui/x-data-grid-premium";
import Joi from "joi";

export interface IExplorerQuery {
  tableId?: string;
  theme?: string;
  paginationModel: GridPaginationModel;
}

export const explorerQuerySchema = Joi.object({
  tableId: Joi.string().regex(/^peg\-sandbox:explorer\.[^\.]+$/),
  theme: Joi.string().regex(/^[a-z0-9_]{5,200}$/),
  paginationModel: Joi.object({
    page: Joi.number().min(0).required(),
    pageSize: Joi.number().min(0).required(),
  }),
});
