import * as React from "react";

import { GridRenderCellParams } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { colorToRgb } from "../../utils/colorizer";

interface GridCellExpandProps {
  formattedValue: string;
  color?: string;
}

const GridCellExpand = React.memo(function GridCellExpand(
  props: GridCellExpandProps
) {
  const { formattedValue } = props;
  return (
    <Box
      sx={{
        backgroundColor: formattedValue ? colorToRgb(props.color, 0.2) : "none",
        padding: 0.5,
        paddingRight: 1,
        paddingLeft: 1,
        borderRadius: 1,
      }}
    >
      <Typography
        sx={{
          color: props.color,
        }}
      >
        {formattedValue}
      </Typography>
    </Box>
  );
});

interface IProps extends GridRenderCellParams<any, string> {
  color?: string;
}

export default function ColorCodedCell(props: IProps) {
  return (
    <GridCellExpand
      color={props.color}
      formattedValue={props.formattedValue || props.value || ""}
    />
  );
}
