import { useEffect, useState } from "react";

import { getTablePreview, postQuery } from "./tableSlice";
import {
  DataGridPremium,
  GridColDef,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridPinnedColumns,
  GridRenderCellParams,
  GridSortModel,
  getDefaultGridFilterModel,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import useQuery from "../../hooks/useQuery";
import Scroller from "../scroller/Scroller";
import moment from "moment";
import { TableToolbar } from "./TableToolbar";
import { TableFooter } from "./TableFooter";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import { IExplorerQuery, explorerQuerySchema } from "../explorer/ExplorerQuery";
import WebsiteCell from "./WebsiteCell";
import TextCell from "../textCell/TextCell";
import useLocalStorage from "../../hooks/useLocalStorage";
import TableLoading from "./TableLoading";
import { gridOperators } from "../gridOperators/gridOperators";
import { getGbqTables } from "../tableSelect/tableSelectSlice";
import ColorCodedCell from "../colorCodedCell/ColorCodedCell";
import { deleteLabels, postLabels, toSalesforce } from "../swiper/labelsSlice";
import snack from "../../utils/snack";

export const defaultPageSize = 100;

const specialCells: {
  [key: string]: (params: GridRenderCellParams<any, string>) => JSX.Element;
} = {
  website: WebsiteCell,
  semantic_keywords: TextCell,
  score_per_flag: TextCell,
  distinct_good_words: TextCell,
  words_in_menu: TextCell,
};

const parsers: {
  [key: string]: (value: any) => any;
} = {
  date: ({ value }) => value && moment(value).toDate(),
  dateTime: ({ value }) => value && moment(value).toDate(),
  boolean: ({ value }) =>
    typeof value === "string" ? value === "true" : value,
};

export function TableView() {
  const { query, setQuery, pushQuery } =
    useQuery<IExplorerQuery>(explorerQuerySchema);
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useLocalStorage<GridColumnVisibilityModel>(
      "columnsVisibilityModel",
      {} as GridColumnVisibilityModel
    );
  const [pinnedColumns, setPinnedColumns] = useLocalStorage<GridPinnedColumns>(
    "pinnedColumns",
    {} as GridPinnedColumns
  );
  useEffect(() => {
    if (!query.tableId) {
      dispatch(getGbqTables());
    }
  }, []);
  const firstTableId = useSelector(
    (state: RootState) => state.tableSelect.tablesIds[0]
  );
  const tableId = query.tableId || firstTableId;
  const { filterModel, sortModel } = useSelector((state: RootState) =>
    state.tableSelect.tablesById[tableId]?.grid_query
      ? JSON.parse(state.tableSelect.tablesById[tableId].grid_query!)
      : {}
  ) as { filterModel?: GridFilterModel; sortModel?: GridSortModel };
  const dispatch = useDispatch();
  const getPreview = () => {
    if (tableId) {
      dispatch(
        getTablePreview(tableId, {
          offset:
            query.paginationModel?.page * query.paginationModel?.pageSize || 0,
          limit: query.paginationModel?.pageSize || defaultPageSize,
          excludedColumns: Object.keys(columnVisibilityModel).filter(
            (key) => !columnVisibilityModel[key]
          ),
        })
      );
      dispatch(getGbqTables());
    }
  };
  useEffect(() => {
    getPreview();
  }, [
    dispatch,
    tableId,
    query.paginationModel?.page,
    query.paginationModel?.pageSize,
  ]);
  const numRows = useSelector(
    (state: RootState) => state.table.metadata?.numRows || 0
  );
  const sliceTableId = useSelector(
    (state: RootState) => state.table.metadata?.id
  );
  useEffect(() => {
    if (sliceTableId && sliceTableId !== tableId) {
      pushQuery({ tableId: sliceTableId });
    }
  }, [sliceTableId]);

  const rows = useSelector((state: RootState) => {
    const seen = new Set();
    return state.table.rows.filter((row) => {
      const key = row.id || row.line_key || row.place_id || row.website;
      if (seen.has(key)) {
        return false;
      }
      seen.add(key);
      return true;
    });
  });
  const columns: GridColDef[] = useSelector((state: RootState) =>
    state.table.columns.map((column) => ({
      ...column,
      ...(specialCells[column.field] && {
        renderCell: specialCells[column.field],
        valueFormatter: ({ value }) => null,
      }),
      ...(column.field.endsWith("_label") && {
        renderCell: (props) => (
          <ColorCodedCell
            {...props}
            color={
              props.value === "good"
                ? "green"
                : props.value === "bad"
                ? "red"
                : undefined
            }
          />
        ),
      }),
      ...(parsers[column.type] && {
        valueGetter: parsers[column.type],
      }),
      filterOperators: gridOperators[column.type],
    }))
  );
  const [canSubmit, setCanSubmit] = useState(false);
  const status = useSelector((state: RootState) => state.table.status);

  const apiRef = useGridApiRef();

  const handleSubmit = () => {
    const state = apiRef.current?.exportState();
    dispatch(
      postQuery({
        tableId,
        theme: query.theme,
        filterModel: state.filter?.filterModel,
        sortModel: state.sorting?.sortModel,
      })
    );
    setCanSubmit(false);
  };

  useEffect(() => {
    if (query.tableId) {
      apiRef.current?.setFilterModel?.(
        filterModel || getDefaultGridFilterModel()
      );
      apiRef.current?.setSortModel?.(sortModel || []);
    }
  }, [query.tableId, !!filterModel]);

  const getFocusedRowId = () => String(apiRef.current?.state.focus.cell?.id);
  const getSelectedRows = () =>
    Array.from(apiRef.current?.getSelectedRows())
      .map((item) => item[1]?.id || item[1]?.website)
      .filter(Boolean) || [];

  useEffect(() => {
    // listen to keydown event
    const listener = (e: KeyboardEvent) => {
      // if ctrl, alt, shift, meta, ignore
      if (e.ctrlKey || e.altKey || e.shiftKey || e.metaKey) {
        return;
      }
      if (["INPUT", "TEXTAREA"].includes((e.target as any).tagName)) {
        return;
      }
      if (!["g", "b", "d"].includes(e.key)) {
        return;
      }
      e.preventDefault();
      const rowId = getFocusedRowId();
      if (!rowId) {
        return;
      }
      if (!query.theme) {
        snack.warning("Please select a theme first");
        return;
      }

      switch (e.key) {
        case "g":
          dispatch(
            postLabels({
              websites: [rowId],
              action: "good",
              theme: query.theme!,
              table_id: query.tableId!,
            })
          );
          break;
        case "b":
          dispatch(
            postLabels({
              websites: [rowId],
              action: "bad",
              theme: query.theme!,
              table_id: query.tableId!,
            })
          );
          break;
        case "d":
          dispatch(
            deleteLabels({
              websites: [rowId],
              theme: query.theme!,
            })
          );
          break;
        default:
          break;
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [query.theme]);

  if (!columns.length) {
    return <TableLoading />;
  }
  return (
    <Paper>
      <Scroller>
        <DataGridPremium
          apiRef={apiRef}
          density="compact"
          rows={status === "empty" ? [] : rows}
          columns={columns}
          unstable_cellSelection
          initialState={{
            pinnedColumns,
            pagination: {
              paginationModel: {
                pageSize: defaultPageSize,
              },
            },
            filter: {
              filterModel,
            },
            sorting: {
              sortModel,
            },
            columns: {
              columnVisibilityModel,
            },
          }}
          getRowId={(row) =>
            row.id ||
            row.line_key ||
            row.place_id ||
            row.website ||
            Math.random()
          }
          checkboxSelection={!!query.theme}
          disableRowSelectionOnClick
          onPinnedColumnsChange={(pinnedColumns) => {
            setPinnedColumns(pinnedColumns);
          }}
          slots={{
            toolbar: () => (
              <TableToolbar
                getSelectedRows={getSelectedRows}
                handleSubmit={handleSubmit}
                canSubmit={canSubmit}
                handleRefresh={getPreview}
              />
            ),
            footer: TableFooter,
            loadingOverlay: LinearProgress,
          }}
          loading={status === "loading"}
          onFilterModelChange={() => {
            if (!canSubmit) {
              setCanSubmit(true);
            }
          }}
          onSortModelChange={() => {
            if (!canSubmit) {
              setCanSubmit(true);
            }
          }}
          // filterModel={filterModel || getDefaultGridFilterModel()}
          // sortModel={sortModel || []}
          rowCount={numRows}
          filterMode="server"
          // disableRowGrouping
          // disableAggregation
          sortingMode="server"
          paginationMode="server"
          rowHeight={50}
          // columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(columnVisibilityModel) => {
            // remove all "true" values as they are the default
            Object.keys(columnVisibilityModel).forEach((key) => {
              if (columnVisibilityModel[key]) {
                delete columnVisibilityModel[key];
              }
            });
            setColumnVisibilityModel(columnVisibilityModel);
          }}
          onPaginationModelChange={(paginationModel) => {
            setQuery({ paginationModel });
          }}
          paginationModel={{
            page: Number(query.paginationModel?.page) || 0,
            pageSize:
              Number(query.paginationModel?.pageSize) || defaultPageSize,
          }}
        />
      </Scroller>
    </Paper>
  );
}
