import * as React from "react";
import Box from "@mui/material/Box";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import MUILink from "@mui/material/Link";
import { colorToRgb } from "../../utils/colorizer";

interface GridCellExpandProps {
  value: string;
}

const allowedNames = {
  universe: true,
  refined: true,
  size: true,
  seed: true,
  features: true,
  reviewed: true,
};

const GridCellExpand = React.memo(function GridCellExpand(
  props: GridCellExpandProps
) {
  const { value } = props;
  const tableId = value.split(".").pop();
  let color = undefined;
  if (
    tableId === "all_companies" ||
    tableId?.split("_").pop()! in allowedNames
  ) {
    color = "green";
  } else if (tableId?.startsWith("t_")) {
    color = "orange";
  } else {
    color = "red";
  }

  return (
    <Box
      sx={{
        // height: "100%",
        padding: 0.5,
        paddingRight: 1,
        paddingLeft: 1,
        borderRadius: 1,
        display: "flex",
        alignItems: "center",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        backgroundColor: colorToRgb(color, 0.2),
      }}
    >
      <MUILink
        component={Link}
        sx={{
          textDecoration: "none",
          color,
        }}
        to={{
          pathname: `/explorer`,
          search: `?tableId=${value}`,
        }}
        target="_blank"
      >
        {tableId}
      </MUILink>
    </Box>
  );
});

export default function TableLinkCell(
  params: GridRenderCellParams<any, string>
) {
  return <GridCellExpand value={params.value || ""} />;
}
