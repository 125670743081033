import { GridToolbarContainer } from "@mui/x-data-grid/components/containers/GridToolbarContainer";

import Divider from "@mui/material/Divider";
import Box from "@mui/system/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import Tooltip from "@mui/material/Tooltip";
import AddPersonIcon from "@mui/icons-material/PersonAdd";
import RemovePersonIcon from "@mui/icons-material/PersonRemove";
import PasswordIcon from "@mui/icons-material/Password";
import { addUser, deleteUsers, resetUserPassword } from "./usersSlice";

interface IProps {
  getSelectedRows: () => string[];
  handleRefresh: () => void;
}

export function UsersTableToolbar(props: IProps) {
  const dispatch = useDispatch();
  const isLoading = useSelector(
    (state: RootState) => state.table.status === "loading"
  );
  return (
    <>
      <GridToolbarContainer sx={{ padding: 1 }}>
        <Tooltip title="Refresh table data">
          <span>
            <IconButton disabled={isLoading} onClick={props.handleRefresh}>
              <RefreshIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Button
          variant="text"
          disabled={isLoading}
          startIcon={<RemovePersonIcon />}
          onClick={() => {
            const selectedUsers = props.getSelectedRows();
            if (
              window.confirm(
                `Deleting users: ${selectedUsers
                  .map((email) => email)
                  .join(", ")}. Are you sure?`
              )
            ) {
              dispatch(deleteUsers(selectedUsers));
            }
          }}
        >
          Remove selected users
        </Button>

        <Button
          variant="text"
          disabled={isLoading}
          // icon for resetting password
          startIcon={<PasswordIcon />}
          onClick={() => {
            const selectedUsers = props.getSelectedRows();
            if (
              window.confirm(
                `Resetting password for users: ${selectedUsers
                  .map((email) => email)
                  .join(", ")}. Are you sure?`
              )
            ) {
              for (const selectedUser of selectedUsers) {
                dispatch(resetUserPassword(selectedUser));
              }
            }
          }}
        >
          Reset password
        </Button>

        <Box sx={{ flexGrow: 1 }} />
        <Button
          disabled={isLoading}
          variant="text"
          startIcon={<AddPersonIcon />}
          onClick={() => {
            const email = window.prompt("Enter user email");
            if (email) {
              dispatch(addUser(email));
            }
          }}
        >
          Add user
        </Button>
      </GridToolbarContainer>
      <Divider />
    </>
  );
}
