import SpacingBox from "../SpacingBox";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { IPhoneMask, upsertPhoneMask } from "./phoneMaskingSlice";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

export default function PhoneMaskForm(props: IPhoneMask) {
  const { register, errors, handleSubmit } = useForm<IPhoneMask>();
  const dispatch = useDispatch();
  return (
    <Box sx={{ padding: 2, display: "flex", flexDirection: "row" }}>
      <TextField
        size="small"
        type="tel"
        name="agent"
        defaultValue={props.agent}
        inputProps={{
          maxLength: 10,
        }}
        inputRef={register({
          required: true,
          pattern: {
            value: /^\d{10}$/,
            message: "US phone number, 10 digits",
          },
        })}
        helperText={errors.agent?.message}
        error={!!errors.agent}
        fullWidth
        label="Agent"
      />
      <SpacingBox />
      <TextField
        size="small"
        type="tel"
        name="broker"
        inputProps={{
          maxLength: 10,
        }}
        inputRef={register({
          required: true,
          pattern: {
            value: /^\d{10}$/,
            message: "US phone number, 10 digits",
          },
        })}
        helperText={errors.broker?.message}
        error={!!errors.broker}
        fullWidth
        contentEditable={false}
        value={props.broker}
        label="Broker"
      />
      <SpacingBox />
      <TextField
        size="small"
        type="tel"
        name="recipient"
        helperText={errors.recipient?.message}
        error={!!errors.recipient}
        inputProps={{
          maxLength: 10,
        }}
        defaultValue={props.recipient}
        inputRef={register({
          required: true,

          pattern: {
            value: /^\d{10}$/,
            message: "US phone number, 10 digits",
          },
        })}
        fullWidth
        label="Recipient"
      />
      <SpacingBox />
      <Button
        variant="outlined"
        onClick={handleSubmit((data) => {
          dispatch(upsertPhoneMask(data));
        })}
      >
        SAVE
      </Button>
    </Box>
  );
}
