import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../../store";
import api from "../../utils/api";
import { ISliceStatus } from "../../interfaces";
import { batch } from "react-redux";
import { getTablePreview } from "../table/tableSlice";

interface IState {
  status: ISliceStatus;
}
const initialState: IState = {
  status: "idle",
};

export const semanticSearchSlice = createSlice({
  name: "semanticSearch",
  initialState,
  reducers: {
    statusChanged: (state, action: PayloadAction<ISliceStatus>) => {
      state.status = action.payload;
    },
  },
});

export const { statusChanged } = semanticSearchSlice.actions;

export interface ISearchPhrase {
  weight: number;
  label: string;
  searchPhrase: string;
}

interface IPostSemanticSearchQuery {
  tableId: string;
  theme?: string;
  searchPhrases: ISearchPhrase[];
}
export const postSemanticSearchQuery =
  (body: IPostSemanticSearchQuery): AppThunk =>
  async (dispatch) => {
    if (!body.theme) {
      if (
        !window.confirm(
          "You didn't select a theme. Are you sure you want to continue?"
        )
      ) {
        return;
      }
    }
    dispatch(statusChanged("loading"));
    const res = await api.fetch({
      path: `/gbqSemanticSearch`,
      method: "POST",
      body: body,
    });
    if (res.ok) {
      batch(() => {
        dispatch(getTablePreview(res.payload.queryDestinationTableId));
        dispatch(statusChanged("ready"));
      });
    } else {
      dispatch(statusChanged("error"));
    }
  };

export default semanticSearchSlice.reducer;
