import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";

interface IProps {
  additionalOffset?: number;
}

export default function Scroller(props: React.PropsWithChildren<IProps>) {
  const [height, setHeight] = React.useState(0);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const offset = containerRef.current?.offsetTop || 0;
  const theme = useTheme();
  const xsOrSmaller = useMediaQuery(theme.breakpoints.down("xs"));
  React.useEffect(() => {
    setHeight(window.innerHeight - offset - (props.additionalOffset || 0));
  }, [offset, props.additionalOffset]);

  const blockHeight = height - parseInt(theme.spacing(xsOrSmaller ? 0.5 : 3));
  return (
    <div
      ref={containerRef}
      style={{
        display: "flex",
        flexDirection: "column",
        maxHeight: "100vh",
        flex: 1,
        height: blockHeight,
      }}
    >
      {props.children}
    </div>
  );
}

export function HorizontalScroller(props: React.PropsWithChildren<IProps>) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        "&::-webkitScrollbar": {
          display: "none",
        },
        MsOverflowStyle: "none",
        scrollbarWidth: "none",
        overflow: "auto",
        marginRight: theme.spacing(-3),
        marginBottom: theme.spacing(-3),
        marginLeft: theme.spacing(-3),
      }}
    >
      <Box
        sx={{
          alignItems: "flex-start",
          display: "flex",
          flexDirection: "row",
          paddingRight: theme.spacing(3),
          paddingBottom: theme.spacing(3),
          paddingLeft: theme.spacing(3),
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
}
