import { GridToolbarContainer } from "@mui/x-data-grid/components/containers/GridToolbarContainer";
import { GridToolbarColumnsButton } from "@mui/x-data-grid/components/toolbar/GridToolbarColumnsButton";
import { GridToolbarFilterButton } from "@mui/x-data-grid/components/toolbar/GridToolbarFilterButton";

import Divider from "@mui/material/Divider";
import Box from "@mui/system/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { getOutreachMessages, postOutreachMessages } from "./smsOutreachSlice";
import { downloadString } from "../../utils/downloadString";
import { GridToolbarExport } from "@mui/x-data-grid-premium";
import moment from "moment";

const downloadSample = () =>
  downloadString(
    "sms-outreach input.csv",
    "agent_phone_number,recipient_phone_number,answered_text_followup,not_answered_text_followup,voicemail_url,voicemail_text_followup,name,title,company,agent\n+12676325664,+12676325664,My custom answered_text_followup message!,My custom not_answered_text_followup message!,http://demo.twilio.com/docs/classic.mp3,My custom voicemail_text_followup message!,John Doe,CEO,Acme Corp,James Bond"
  );

export function SmsOutreachToolbar() {
  const isLoading = useSelector(
    (state: RootState) => state.smsOutreach.status === "loading"
  );
  const dispatch = useDispatch();
  return (
    <>
      <GridToolbarContainer sx={{ padding: 1 }}>
        <IconButton
          disabled={isLoading}
          onClick={() => dispatch(getOutreachMessages())}
        >
          <RefreshIcon />
        </IconButton>
        <GridToolbarExport
          csvOptions={{
            fileName: `outreach export ${moment().format("YYYY-MM-DD ha")}.csv`,
          }}
          excelOptions={{
            fileName: `outreach export ${moment().format(
              "YYYY-MM-DD ha"
            )}.xlsx`,
          }}
        />

        <Box sx={{ flexGrow: 1 }} />
        <Button variant="outlined" onClick={downloadSample}>
          Download input sample
        </Button>
        <Button
          variant="outlined"
          onClick={() => dispatch(postOutreachMessages())}
        >
          Upload input CSV
        </Button>
      </GridToolbarContainer>
      <Divider />
    </>
  );
}
