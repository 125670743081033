import Avatar from "@mui/material/Avatar";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Tooltip from "@mui/material/Tooltip";
import { stringToColor } from "../utils/stringToColor";

export default function UserAvatar(props: { name?: string }) {
  const name = props.name || "Fusion Point";
  return (
    <ListItemAvatar>
      <Tooltip title={name}>
        <Avatar {...stringAvatar(name)} />
      </Tooltip>
    </ListItemAvatar>
  );
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name).color,
    },
    children: `${name.match(/\b(\w)/g)?.join("")}`,
  };
}
