import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import { GridRenderCellParams } from "@mui/x-data-grid";

interface GridCellExpandProps {
  value: string;
  formattedValue?: string;
}

const GridCellExpand = React.memo(function GridCellExpand(
  props: GridCellExpandProps
) {
  const { value, formattedValue } = props;

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopperOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopperClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box
      onMouseEnter={handlePopperOpen}
      onMouseLeave={handlePopperClose}
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      {formattedValue || value}
      <Popper
        sx={{ zIndex: 3, maxHeight: "50vh" }}
        open={open}
        anchorEl={anchorEl}
        placement="right-end"
      >
        <Paper elevation={8} sx={{ overflow: "auto", maxHeight: "50vh" }}>
          <Typography sx={{ p: 1, whiteSpace: "pre-wrap" }}>
            {formattedValue || value?.replace(/,|;/g, "\n")}
          </Typography>
        </Paper>
      </Popper>
    </Box>
  );
});

export default function TextCell(params: GridRenderCellParams<any, string>) {
  return (
    <GridCellExpand
      value={params.value || ""}
      formattedValue={params.formattedValue}
    />
  );
}
