import {
  GridFilterOperator,
  getGridBooleanOperators,
  getGridDateOperators,
  getGridNumericOperators,
  getGridSingleSelectOperators,
  getGridStringOperators,
} from "@mui/x-data-grid-premium";
import { gridStringOperators } from "./gridStringOperators";

export const gridOperators: {
  [key: string]: GridFilterOperator[];
} = {
  string: gridStringOperators,
  number: getGridNumericOperators(),
  boolean: getGridBooleanOperators(),
  date: getGridDateOperators(),
  dateTime: getGridDateOperators(true),
  singleSelect: getGridSingleSelectOperators(),
};
