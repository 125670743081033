import Box from "@mui/material/Box";
import { TableView } from "../table/TableView";
import SemanticSearchView from "../semanticSearch/SemanticSearchView";
import SpacingBox from "../SpacingBox";

export default function ExplorerView() {
  return (
    <Box>
      <SemanticSearchView />
      <SpacingBox size={1} />
      <TableView />
    </Box>
  );
}
