import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "../../utils/api";
import { AppThunk } from "../../store";
import { ISliceStatus } from "../../interfaces";
import snack from "../../utils/snack";

export interface IPhoneMask {
  agent: string;
  recipient: string;
  broker: string;
}

interface IState {
  masks: IPhoneMask[];
  status: ISliceStatus;
  exporting: boolean;
}

const initialState: IState = {
  masks: [],
  status: "idle",
  exporting: false,
};

export const phoneMaskingSlice = createSlice({
  name: "newspapers",
  initialState,
  reducers: {
    statusChanged: (state, action: PayloadAction<ISliceStatus>) => {
      state.status = action.payload;
    },
    fetched: (state, action: PayloadAction<any[]>) => {
      state.masks = action.payload;
      state.status = "ready";
    },
  },
  extraReducers: {
    "session/logOut": () => initialState,
  },
});

export const { statusChanged, fetched } = phoneMaskingSlice.actions;

export const upsertPhoneMask =
  (payload: IPhoneMask): AppThunk =>
  async (dispatch) => {
    dispatch(statusChanged("loading"));
    const res = await api.fetch({
      method: "POST",
      path: "/phoneMask",
      body: payload,
    });
    if (res.ok) {
      snack.success("Phone mask saved!");
      dispatch(getPhoneMasks());
    }
  };

export const getPhoneMasks = (): AppThunk => async (dispatch) => {
  dispatch(statusChanged("loading"));
  const res = await api.fetch({
    method: "GET",
    path: "/phoneMasks",
  });
  if (res.ok) {
    dispatch(fetched(res.payload));
  }
};

export default phoneMaskingSlice.reducer;
