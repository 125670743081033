import BatchesView from "../batches/BatchesList";
import BatchFormView from "../batches/BatchFormView";
import { useTheme } from "@mui/material/styles";
import SpacingBox from "../SpacingBox";
import Box from "@mui/material/Box";
import { HorizontalScroller } from "../scroller/Scroller";

export default function DataManagerView() {
  const theme = useTheme();
  return (
    <HorizontalScroller>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <BatchFormView />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <SpacingBox />
        <BatchesView />
        <SpacingBox />
      </Box>
    </HorizontalScroller>
  );
}
