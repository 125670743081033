const c0 = `all companies in this batch`;
const c1 = `
1a:
distinct_good_words contains any of (nation|states|acquisitions|franch*)
- OR total_score < 200 
- - AND distinct_words_count > 0
- OR distinct_good_words contains any of (global) 
- - AND number_of_locations not blank 
- - AND number_of_locations > 8
- OR distinct_flags contains any of (menu_bad_words)
- - AND number_of_locations > 20
- - AND known_franchisor is blank 
AND distinct_flags NOT contains any of (funded|company_name_|keywords_critical_words|subdomain|bad_domain|bad_domain_extension|linkedin_non_us|keywords_very_bad_words|menu_very_bad_words|linkedin_bad_industries)
AND If NOT present in elastic 
- AND score <10000
AND founded is blank 
- OR founded BETWEEN 1990 AND (CURRENT_YEAR - 2)

--
1b:
distinct_states_count > 2 
- OR number_of_locations > 8
- OR distinct_flags Contains any of (menu_bad_words)
- - AND distinct_good_words not contains any of (nation*)
- - AND number_of_locations > 20
- - AND known_franchisor is blank 
AND distinct_flags not contains any of (funded|company_name_|keywords_critical_words|subdomain|bad_domain|bad_domain_extension|linkedin_non_us|keywords_very_bad_words|menu_very_bad_words|linkedin_bad_industries)
AND distinct_words_count > 0
AND founded is blank 
- OR founded BETWEEN 1990 AND (CURRENT_YEAR - 2)
AND If NOT present in elastic 
- AND score <10000

--
1c:
total_score < 500
- OR distinct_words_count > 4
- OR distinct_flags Contains any of (menu_bad_words)
- - AND distinct_good_words not contains any of (nation*)
- - AND number_of_locations > 20
- - AND known_franchisor is blank 
AND distinct_flags not contains any of (funded|company_name_|keywords_critical_words||subdomain|bad_domain|bad_domain_extension|linkedin_non_us|keywords_very_bad_words|menu_very_bad_words|linkedin_bad_industries)
AND If NOT present in elastic 
- AND score <10000
AND founded is blank 
- OR founded BETWEEN 1990 AND (CURRENT_YEAR - 2)
`;
const c2 = `
2a:
distinct_flags contains phone_or_email_in_header 
AND distinct_states_count is blank or 1 
AND number_of_locations is blank or < 5
AND distinct_flags contains any of (company_name_|keywords_critical_words)
AND founded is blank
- OR founded BETWEEN 1990 AND (CURRENT_YEAR - 2)
--
2b:
founded is blank
- OR founded BETWEEN 1990 AND (CURRENT_YEAR - 2)
AND 
- known_franchisor is not blank
- OR max_sitemap_year < CURRENT_YEAR - 2 AND NOT BLANK
- OR max_website_year < CURRENT_YEAR - 2 AND NOT BLANK
- OR distinct_flags contains any of (menu_bad_words|menu_very_bad_words|keywords_critical_words|bad_domain|bad_domain_extension|clearbit_non_us|fc_non_us|linkedin_non_us|elastic_google_non_us|funded|company_name_|keywords_very_bad_words)
--
2c
distinct_flags contains any of (subdomain|fc_bad_industries|elastic_linkedin_bad_industries| outscraper_bad_category|linkedin_bad_industries| diffbot_bad_industries|clearbit_bad_sub_industries| elastic_yelp_bad_categories|bad_naics)
AND founded is blank
- OR founded BETWEEN 1990 AND (CURRENT_YEAR - 2)
`;
const c3 = `
3a:
number_of_locations is blank or < 5 AND total_score > 250 AND distinct_good_words not contains any of (nation|franchisor|wellness|multiunit|geographies|territories|network|distribution|royalt|licensing)
OR total_score > 4000
OR distinct_flags contains linkedin_small
OR distinct_flags contains "phone_or_email_in_header" AND distinct_states_count is blank or < 2 AND number_of_locations is blank or < 9
OR flags_count > 9
`;
const c4 = `
4a:
total_score > 2000 AND distinct_good_words not contains any of (nation|states|franchisor|wellness|multiunit|geographies|territories|network|distribution|royalt|licensing)
OR distinct_flags contains any of (elastic_yelp_bad_specialty|linkedin_bad_specialties|elastic_linkedin_bad_specialties) 
- AND total_score > 1000
`;
const c5 = `
5a:
distinct_flags contains "present_in_elastic"
--
5b:
all other cases
`;
export default [c0, c1, c2, c3, c4, c5].map((item) => item.trim());
